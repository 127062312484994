<template>
  <div class="app-page login-page">
    <div class="login-container request-password-container app-card">
      <div :class="['login-card', { blurred: loading }]">
        <img src="../../assets/Logo-primary.svg" />
        <div class="text-left mt-2">
          <p>
            Ange din e-postadess som är kopplad till kontot. Om e-postadressen
            är registrerad kommer en länk att skickas till den för återställning
            av lösenordet
          </p>
        </div>
        <form>
          <div class="mt-5">
            <input
              type="text"
              class="form-control w-100"
              :placeholder="$t('common.email')"
              v-model="email"
            />
          </div>
          <div class="mt-3 text-left">
            <router-link to="/login">Tillbaka till inloggning</router-link>
          </div>
          <div class="mt-4 pt-3">
            <button
              class="btn btn-secondary w-100"
              type="submit"
              :disabled="!email"
              @click.prevent="onSubmit"
            >
              Skicka
            </button>
          </div>
        </form>
      </div>
      <div v-if="loading" class="login-loading-hud app-card">
        <div class="loader awesome-spin"></div>
      </div>
    </div>
  </div>
</template>

<script>
import TokenService from "../../services/token.service.js";
export default {
  data: function () {
    return {
      email: "",
      loading: false,
    };
  },
  methods: {
    onSubmit: function () {
      this.loading = true;
      TokenService.requestPasswordRest(this.email)
        .then(() => {
          this.$bus.dispatch("showToastSuccess", {
            title: "Återställningslänk skickad",
            message: `En länk för att återställa ditt lösenord har skickats till angiven e-postadress`,
          });
        })
        .catch((e) => {
          console.log(e);
          this.$bus.dispatch("showToastFailure", {
            title: "Återställningslänk kunde inte skickas",
            message: `Något gick fel när länken för återställning av lösenord skulle skickas`,
          });
        })
        .finally(() => {
          this.loading = false;
          this.email = "";
        });
    },
  },
};
</script>

<style scoped>
.request-password-container {
  max-width: 400px;
}
</style>