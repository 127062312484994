import RecipebankService from "../../services/recipebank.service";

const initialState = () => {
  return {
    recipeBanks: {
      metadata: {},
      list: []
    }
  }
}

export const admin = {
  namespaced: true,
  state: initialState(),
  getters: {
    totalRecipeBanks: state => {
      if (state.recipeBanks?.metadata?.total_records) {
        return state.recipeBanks.metadata.total_records
      }
      return 0;
    }
  },
  actions: {
    setInitState({ commit }) {
      commit("setInitState", initialState())
    },
    searchRecipeBanks({ commit }, data) {
      return RecipebankService.search(data.filters)
        .then(
          result => {
            commit('setRecipeBanks', { list: result.recipeBanks, metadata: result.metadata })
            return Promise.resolve(result)
          },
          error => {
            return Promise.reject(error);
          }
        )
    },
  },
  mutations: {
    setInitState(state, initialState) {
      Object.assign(state, initialState)
    },
    setRecipeBanks(state, recipeBanks) {
      state.recipeBanks = recipeBanks;
    }
  }
};