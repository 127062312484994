import api from "./api"

class RecipeBankService {
  get(id) {
    return api.get(`/recipebanks/${id}`)
      .then(response => response.data?.recipebank)
  }

  createRecipeBank(data) {
    return api.post('/recipebanks', data)
      .then((response) => {
        return response.data?.recipebank
      })
  }

  getRecipeBankSummary() {
    return api.get("/recipebanks")
      .then(response => response.data?.recipeBanks)
  }

  removeRecipeBank(recipeBankId) {
    return api.delete(`/recipebanks/${recipeBankId}`)
  }

  addMember(recipeBankId, email) {
    return api.post(`/recipebanks/${recipeBankId}/member`, { email: email })
  }

  importRecipes(recipeBankId, file) {
    var formData = new FormData();
    formData.append("recipes.json", file);
    return api.post(`/recipebanks/${recipeBankId}/import/file`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
  }

  exportRecipesFromBank(recipeBankId) {
    return api.get(`/recipebanks/${recipeBankId}/export`)
      .then(response => response.data?.recipesManifest)
  }

  removeMember(recipeBankId, userId) {
    return api.delete(`/recipebanks/${recipeBankId}/member/${userId}`)
  }

  search(filters) {
    return api.get(`/admin/recipebanks?text=${filters.text}&page=${filters.page}&page_size=${filters.pageSize}&sort=${filters.sort}`)
      .then(response => response.data);
  }

  update(recipeBank) {
    return api.patch(`/recipebanks/${recipeBank.id}`, { name: recipeBank.name })
  }

  inviteUser(recipeBankId, userEmail) {
    return api.post(`/recipebanks/${recipeBankId}/invitations`, { email: userEmail })
  }
}

export default new RecipeBankService();