<template>
  <b-modal
    :id="modalId"
    v-model="show"
    :title="$t('recipe.updateRecipe')"
    :visible="show"
    no-close-on-backdrop
    no-close-on-esc
    @hide="onClose"
    @ok="onSubmit"
    :cancel-title="$t('common.cancel')"
    :ok-title="$t('common.save')"
  >
    <input
      type="text"
      class="form-control"
      :placeholder="$t('common.name')"
      v-model="recipe.name"
    />
    <input
      type="text"
      class="form-control mt-3"
      :placeholder="$t('common.source')"
      v-model="recipe.source"
    />
    <textarea
      v-model="recipe.notes"
      cols="30"
      rows="4"
      class="form-control mt-3"
      :placeholder="$t('common.notes')"
    ></textarea>
    <b-form-tags
      input-id="recipe-tags"
      v-model="recipe.tags"
      class="mt-3"
      :placeholder="$t('common.tags')"
      :invalidTagText="$t('recipe.invalidTag')"
      :duplicateTagText="$t('recipe.duplicateTag')"
    >
    </b-form-tags>
    <upload-file class="mt-4" v-model="recipe.files"></upload-file>
    <b-form-rating
      v-model="recipe.rating"
      size="lg"
      variant="variant"
      class="rating-viewer mt-4"
    ></b-form-rating>
  </b-modal>
</template>

<script>
import RecipesService from "../../services/recipes.service.js";
import ImagesService from "../../services/images.service.js";
import UploadFile from "../widgets/upload-file.vue";

export default {
  components: {
    UploadFile,
  },
  props: {
    modalId: {
      type: String,
      required: true,
    },
  },
  data: function () {
    return {
      show: false,
      loading: false,
      error: "",
      warning: "",
      recipe: {},
      originalImages: [],
    };
  },
  methods: {
    onClose: function (event) {
      this.recipe = this.emptyRecipe();
      if (event.trigger === "cancel" || event.trigger === "headerclose") {
        this.$emit("cancel");
      }
      this.show = false;
    },
    onSubmit: async function () {
      this.loading = true;
      var images = this.recipe.files;
      console.log("UPDATING recipe");
      console.log("Images before filter: " + images?.length ?? 0);

      var imageFilterResult = this.filterImages(images);
      this.error = this.$t("recipe.unableSaveRecipe");

      var notifyUpdated = false;
      RecipesService.updateRecipe(this.recipe)
        .then((recipe) => {
          this.error = "";
          this.warning = this.$t("recipe.unableSaveImages");
          if (imageFilterResult.toInsert.length < 1) {
            this.$emit("recipeUpdated", { recipe: recipe });
            return Promise.all([Promise.resolve(recipe)]);
          } else {
            notifyUpdated = true;
            return Promise.all([
              Promise.resolve(recipe),
              ImagesService.uploadImages(imageFilterResult.toInsert),
            ]);
          }
        })
        .then((results) => {
          console.log(results);
          if (results.length > 1) {
            var imageUrls = [...images.filter((i) => i.url).map((i) => i.url)];
            imageUrls.push(...results[1]);
            RecipesService.syncRecipes({
              recipeID: results[0].id,
              urls: imageUrls,
            }).finally(() => {
              if (notifyUpdated) {
                this.$emit("recipeUpdated", { recipe: results[0] });
              }
            });
          }
          return Promise.resolve(results[0]);
        })
        .then(() => (this.warning = ""))
        .catch((e) => console.log(e))
        .finally(() => {
          this.loading = false;
        });
    },
    emptyRecipe: function () {
      return {
        recipeBankID: this.$store.getters["recipebanks/selectedRecipeBank"],
        name: "",
        source: "",
        notes: "",
        tags: [],
        files: [],
        status: 1,
        rating: 0,
      };
    },
    filterImages: function (images) {
      var result = { toInsert: [], toDelete: [] };
      images.forEach((i) => {
        if (!i.url) {
          result.toInsert.push(i);
        }
      });
      return result;
    },
  },
  mounted() {
    this.recipe = this.emptyRecipe();
    this.$bus.on("showFullUpdateModal", (data) => {
      this.recipe = { ...data.recipe, files: [] };
      if (data.recipe.files && data.recipe.files.length > 0) {
        this.originalImages.push(...data.recipe.files.map((f) => f.url));
        this.$nextTick(() => this.recipe.files.push(...data.recipe.files));
      }
      this.show = true;
    });
  },
  beforeDestroy() {
    this.$bus.remove("showFullUpdateModal");
  },
};
</script>

<style scoped>
.my-upload {
  border-color: #ced4da !important;
}

.my-upload > .icon {
  color: rgb(73, 80, 87) !important;
}
</style>