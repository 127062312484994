<template>
  <b-modal
    :id="modalId"
    title="Ändra användare"
    :visible="show"
    no-close-on-backdrop
    @hide="onClose"
    @ok="onSubmit"
    :cancel-title="$t('common.cancel')"
    ok-title="Spara"
  >
    <form>
      <input
        type="text"
        class="form-control"
        :placeholder="$t('common.name')"
        v-model="user.name"
      />
      <div class="input-group mt-3">
        <input
          :type="passwordInputType"
          v-model="user.password"
          class="form-control"
          placeholder="Lösenord"
          aria-describedby="user-input1"
          autocomplete="current-password"
        />
        <div class="input-group-append">
          <span
            class="input-group-text"
            id="user-input1"
            style="min-width: 3rem"
            ><font-awesome-icon
              :icon="passwordInputIcon"
              class="clickable"
              @click="showPassword = !showPassword"
            ></font-awesome-icon
          ></span>
        </div>
      </div>
    </form>
  </b-modal>
</template>

<script>
import UserService from "../../services/user.service.js";
export default {
  props: {
    modalId: {
      type: String,
      required: true,
    },
  },
  data: function () {
    return {
      show: false,
      loading: false,
      error: "",
      user: {},
      showPassword: false,
    };
  },
  computed: {
    passwordInputIcon: function () {
      return this.showPassword ? "eye-slash" : "eye";
    },
    passwordInputType: function () {
      return this.showPassword ? "text" : "password";
    },
  },
  methods: {
    onClose: function () {
      this.user = {};
      this.show = false;
    },
    onSubmit: function () {
      this.loading = true;

      var userToUpdate = { ...this.user };
      if (userToUpdate.password === "") {
        userToUpdate.password = null;
      }
      UserService.updateUser(userToUpdate)
        .then(() => {
          this.$bus.dispatch("showToastSuccess", {
            title: "Uppdatering färdig",
            message: `Hej vad det går! Uppdateringen är klar.`,
          });
          this.$emit("userUpdated");
          this.$bvModal.hide(this.modalId);
        })
        .catch((err) => {
          console.log(err);
          this.$bus.dispatch("showToastFailure", {
            title: "Uppdatering misslyckades",
            message: `Hoppsan! Uppdateringen kunde inte göras. Kolla i loggarna för mer information.`,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.$bus.on("showAdminEditUserModal", (data) => {
      this.user = { ...data.user, password: "" };
      this.show = true;
    });
  },
  beforeDestroy() {
    this.$bus.remove("showAdminEditUserModal");
  },
};
</script>

<style>
</style>