<template>
  <b-modal
    :id="modalId"
    title="Importera recept"
    :visible="show"
    no-close-on-backdrop
    @hide="onClose"
    @ok="onSubmit"
    :cancel-title="$t('common.cancel')"
    :ok-title="$t('common.save')"
    :ok-disabled="!this.recipesFile"
  >
    <p>
      Importera recept genom att ladda upp en fil i json-format med
      receptinformation
    </p>
    <b-form-file
      id="recipe-import-upload-input"
      ref="recipeImportUpload"
      accept="application/json"
      no-drop
      plain
      hidden
      v-model="recipesFile"
    ></b-form-file>
    <div class="mt-4 json-import-text">
      <p v-if="!recipesFile">Ingen fil vald</p>
      <p v-else>{{ recipesFile.name }}</p>
    </div>
    <button class="btn btn-secondary" @click="onBrowseFiles">
      <font-awesome-icon
        icon="cloud-upload-alt"
        class="mr-2"
      ></font-awesome-icon>
      Ladda upp fil
    </button>
  </b-modal>
</template>

<script>
import RecipeBankService from "../../services/recipebank.service.js";
export default {
  props: {
    modalId: {
      type: String,
      required: true,
    },
  },
  data: function () {
    return {
      show: false,
      recipesFile: null,
      recipeBankId: 0,
    };
  },
  methods: {
    onClose: function () {
      this.show = false;
      this.recipesFile = null;
    },
    onSubmit: function () {
      if (this.recipesFile) {
        RecipeBankService.importRecipes(this.recipeBankId, this.recipesFile)
          .then(() =>
            this.$bus.dispatch("showToastSuccess", {
              title: "Import av recept sänd",
              message: `Fort var det gjort! Dina recept håller på att importeras till din receptbank. Om importen innehöll många recept kan det ta en liten stund innan alla dyker upp`,
            })
          )
          .then(() => {
            this.$emit("importPerformed");
          })
          .catch((e) => {
            console.log(e);
            return this.$bus.dispatch("showToastFailure", {
              title: "Kunde inte importera recept",
              message: `Ojdå! 'Något gick fel när recepten skulle importeras. Uppdatera sidan och försök igen eller kontakta supporten'`,
            });
          });
      }
    },
    onBrowseFiles: function () {
      this.$refs.recipeImportUpload.$el.click();
    },
  },
  mounted() {
    this.$bus.on(this.modalId, (data) => {
      this.recipeBankId = data.recipeBankId;
      this.show = true;
    });
  },
  beforeDestroy() {
    this.$bus.remove(this.modalId);
  },
};
</script>

<style scoped>
.json-import-text {
  font-style: italic;
}
</style>