<template>
  <div class="container app-page-card">
    <add-recipe-bank-user-modal
      modalId="addRecipeBankUserModal"
    ></add-recipe-bank-user-modal>
    <import-recipes-modal modalId="importRecipes"> </import-recipes-modal>
    <add-recipe-bank-modal modalId="addRecipeBankModal"></add-recipe-bank-modal>
    <div class="recipe-bank">
      <div class="profile-section-header">Mina receptbanker</div>
      <div v-if="recipeBankCount < 1">
        Du är inte medlem i någon receptbank ännu
      </div>
      <div v-else class="mt-4 container">
        <div v-if="isWideView" class="row table-row profile-data-header">
          <div class="col-sm-4 mt-4">
            <div>Namn</div>
          </div>
          <div class="col-sm-4 mt-4">
            <div>Antal recept</div>
          </div>
          <div class="col-sm-3 mt-4">
            <div>Antal medlemmar</div>
          </div>
          <div class="col-sm-1 mt-4"></div>
          <div class="col-12 list-header-divider"></div>
        </div>
        <div v-for="(summary, index) in recipebanks" :key="index">
          <hr v-if="index > 0" />
          <div class="row table-row mt-2 default-text">
            <div class="col-sm-4">
              <div v-if="isWideView">{{ summary.name }}</div>
              <div v-else style="font-weight: bolder">
                {{ summary.name }}
              </div>
            </div>
            <div class="col-sm-4">
              <div v-if="isWideView" style="margin-left: 28px">
                {{ summary.recipeCount }}
              </div>
              <div v-else class="text-left">
                {{ summary.recipeCount }} recept
              </div>
            </div>
            <div class="col-sm-3" :id="'recipeBankMembers_' + summary.id">
              <div v-if="isWideView" style="margin-left: 45px">
                {{ summary.users.length }}
              </div>
              <div v-else class="text-left">
                {{ summary.users.length }} användare
              </div>
              <b-tooltip
                :target="'recipeBankMembers_' + summary.id"
                triggers="hover"
              >
                <div
                  v-for="user in summary.users"
                  :key="summary.id + '_' + user.id"
                >
                  {{ user.name }} ({{ user.email }})
                </div>
              </b-tooltip>
            </div>
            <div
              :class="[
                'col-12',
                'col-sm-1',
                'admin-list-cell',
                { adjusted: isWideView },
              ]"
            >
              <b-dropdown
                size="sm"
                variant="link"
                toggle-class="text-decoration-none"
                droprigth
                :no-caret="isWideView"
                :text="'Hantera'"
                :class="['my-secret-class']"
              >
                <template v-if="isWideView" #button-content>
                  <font-awesome-icon icon="ellipsis-v"></font-awesome-icon>
                </template>
                <b-dropdown-item @click="onEditRecipeBank(summary)"
                  >Redigera</b-dropdown-item
                >
                <b-dropdown-item
                  v-if="summary.recipeCount > 0"
                  @click="onExportRecipeBank(summary)"
                  >Exportera</b-dropdown-item
                >
                <b-dropdown-item @click="onImportRecipes(summary)"
                  >Importera recept</b-dropdown-item
                >
                <b-dropdown-item
                  v-if="isOwner(summary)"
                  @click="onRemoveRecipeBank(summary)"
                  >Ta bort</b-dropdown-item
                >
                <b-dropdown-item
                  v-else
                  @click="onRemoveSelfFromRecipeBank(summary)"
                  >Lämna</b-dropdown-item
                >
              </b-dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr />
    <div class="actions">
      <div class="row">
        <div class="col-12">
          <div class="row">
            <div class="col-12">
              <div id="createRecipeBankButton">
                <button
                  class="btn btn-sm btn-primary w-100"
                  @click="onCreateNewRecipeBank"
                  :disabled="!canCreateRecipeBank"
                >
                  Skapa ny receptbank
                </button>
              </div>
              <b-tooltip
                v-if="!canCreateRecipeBank"
                target="createRecipeBankButton"
                triggers="hover"
              >
                Maximalt {{ maxRecipeBanks }} receptbanker tillåtna
              </b-tooltip>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr />
    <div class="section-header mt-5">Inbjudningar</div>
    <div v-if="!hasInvitations" class="font-italic">
      Du har inga inbjudningar att svara på
    </div>
    <div v-else class="container mt-4">
      <div v-if="isWideView" class="row profile-data-header">
        <div class="col-sm-4">
          <div>Namn</div>
        </div>
        <div class="col-sm-4">
          <div>Inbjuden av</div>
        </div>
        <div class="col-sm-4"></div>
        <div class="col-12 list-header-divider"></div>
      </div>
      <div
        v-for="(invitation, index) in invitations"
        :key="invitation.id"
        :class="['row', 'table-row', 'invitation-row', 'default-text', 'mt-2']"
      >
        <div v-if="index > 0" class="col-12"><hr /></div>
        <div :class="['col-sm-4', { 'font-weight-bold': !isWideView }]">
          {{ invitation.recipeBank.name }}
        </div>
        <div class="col-12 col-sm-4">{{ invitation.invitor.name }}</div>
        <div class="col-12 col-sm-4">
          <div :class="['invite-actions', { 'mt-2': !isWideView }]">
            <button
              class="btn btn-primary btn-sm w-100 mr-1"
              @click="onAnswerInvite(invitation.recipeBank.id, true)"
            >
              Gå med
            </button>
            <button
              class="btn btn-outline-danger btn-sm w-100 ml-1"
              @click="onAnswerInvite(invitation.recipeBank.id, false)"
            >
              Nej tack
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RecipeBankService from "../../services/recipebank.service.js";
import UserService from "../../services/user.service.js";
import AddRecipeBankUserModal from "../modals/add-recipebank-user-modal.vue";
import AddRecipeBankModal from "../modals/add-recipebank-modal.vue";
import ImportRecipesModal from "../modals/import-recipes-modal.vue";

export default {
  components: {
    AddRecipeBankUserModal,
    AddRecipeBankModal,
    ImportRecipesModal,
  },
  data: function () {
    return {
      invitations: [],
      invitationsError: false,
    };
  },
  computed: {
    hasInvitations: function () {
      return this.invitations?.length > 0 ?? false;
    },
    recipebanks: function () {
      return this.$store.state.recipebanks.recipeBankSummary;
    },
    recipeBankCount: function () {
      return this.recipebanks.length;
    },
    isWideView: function () {
      return this.$store.getters["isWideView"];
    },
    canCreateRecipeBank: function () {
      if (!this.recipebanks || !this.currentUser) {
        return false;
      }
      return this.recipeBankCount < this.maxRecipeBanks;
    },
    currentUser: function () {
      return this.$store.getters["auth/currentUser"];
    },
    maxRecipeBanks: function () {
      return this.currentUser?.maxRecipeBanks;
    },
  },
  methods: {
    onImportRecipes: function (recipeBank) {
      this.$bus.dispatch("importRecipes", { recipeBankId: recipeBank.id });
    },
    onAnswerInvite: function (recipeBankId, join) {
      UserService.answerRecipeBankInvitation(recipeBankId, join)
        .then(() => this.getRecipeBankInvitationsForUser())
        .then(() => this.$store.dispatch("recipebanks/fetchRecipeBank"))
        .catch((e) => {
          console.log(e);
          this.$bus.dispatch("showToastFailure", {
            title: "Kunde inte svara på inbjudan",
            message:
              "Typiskt! Något gick fel när inbjudan skulle besvaras. Ladda om sidan och försök igen eller kontakta supporten.",
          });
        });
    },
    onEditRecipeBank: function (recipeBank) {
      this.$router.push({ path: `/recipebanks/${recipeBank.id}` });
    },
    onCreateNewRecipeBank: function () {
      this.$bvModal.show("addRecipeBankModal");
    },
    onAddRecipeBankUser: function (recipeBank) {
      this.$bus.dispatch("showAddRecipeBankUserModal", {
        recipeBank: recipeBank,
      });
    },
    onRecipeBankUpdated: function () {
      this.$store.dispatch("recipebanks/fetchRecipeBank");
    },
    onRemoveRecipeBank: function (recipeBank) {
      this.$bvModal
        .msgBoxConfirm(
          `Är du säkert på att du vill ta bort receptbanken '${recipeBank.name}'?`,
          {
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "Ta bort",
            cancelTitle: "Avbryt",
          }
        )
        .then((result) => {
          if (result) {
            return this.$store
              .dispatch("recipebanks/removeRecipeBank", {
                recipeBankId: recipeBank.id,
              })
              .then(() => this.$store.dispatch("recipebanks/fetchRecipeBank"));
          }
          return Promise.resolve();
        })
        .catch((e) => {
          console.log(e);
          this.$bus.dispatch("showToastFailure", {
            title: "Något gick fel!",
            message: `Ojdå! Vi kunde inte ta bort receptbanken '${recipeBank.name}'. Uppdatera sidan och försök igen eller kontakta supporten`,
          });
        });
    },
    isOwner: function (recipeBank) {
      return this.currentUser.id === recipeBank.createdBy;
    },
    onExportRecipeBank: function (recipeBank) {
      RecipeBankService.exportRecipesFromBank(recipeBank.id).then((recipes) => {
        var dataStr =
          "data:text/json;charset=utf-8," + encodeURIComponent(recipes);

        var download = document.createElement("a");
        download.setAttribute("href", dataStr);
        download.setAttribute("download", `${recipeBank.name}_recipes.json`);
        download.click();
        download.remove();
      });
    },
    onRemoveSelfFromRecipeBank: function (recipeBank) {
      this.$bvModal
        .msgBoxConfirm(
          `Är du säkert på att du vill ta bort dig själv som medlem i receptbanken '${recipeBank.name}'?`,
          {
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "Ta bort",
            cancelTitle: "Avbryt",
          }
        )
        .then((result) => {
          if (result) {
            return RecipeBankService.removeMember(
              recipeBank.id,
              this.$store.getters["auth/currentUser"].id
            );
          }
          return Promise.resolve();
        })
        .then(() => this.$store.dispatch("recipebanks/fetchRecipeBank"))
        .catch((e) => {
          console.log(e);
          this.$bus.dispatch("showToastFailure", {
            title: "Något gick fel!",
            message: `Ojdå! Vi kunde inte ta bort dig från receptbanken '${recipeBank.name}'. Uppdatera sidan och försök igen eller kontakta supporten`,
          });
        });
    },
    getRecipeBankInvitationsForUser: function () {
      return UserService.getRecipeBankInvitations(this.currentUser.id)
        .then((result) => {
          this.invitations = [...result];
        })
        .catch((e) => {
          console.log(e);
          this.invitationsError = true;
        });
    },
  },
  mounted() {
    this.getRecipeBankInvitationsForUser();
  },
};
</script>

<style scoped>
.recipe-bank-name {
  font-size: 20px;
  font-weight: 600;
}

.recipebank-actions {
  font-size: 1rem;
}

.recipebank-actions-wide {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.dropdown >>> button {
  text-align: left;
  border: none;
  padding: 0.5rem 0 0 0;
}

.dropdown >>> button:focus,
.dropdown >>> button:focus-visible {
  outline: none;
  box-shadow: none;
}

.adjusted {
  margin-top: -0.5rem;
}

.invite-actions {
  display: flex;
  justify-content: space-between;
}

.invitation-row {
  align-items: center;
}
</style>  