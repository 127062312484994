import Vue from 'vue';
import { BootstrapVue } from 'bootstrap-vue';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserCircle, faTimes, faCloudUploadAlt, faTrashAlt, faPlusCircle, faExternalLinkAlt, faEllipsisV, faCheck, faEdit, faShare, faStar, faUserPlus, faFileExport, faEye, faEyeSlash, faBug, faFilter, faChevronCircleUp, faInfoCircle, faEnvelopeOpen, faEnvelopeOpenText, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faPlusSquare, faTimesCircle, faCommentDots, faImage } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import App from './App.vue';

import bus from './util/eventbus.js';
import store from './state/store.js';
import { router } from './router.js';
import i18n from './i18n/index.js';
import authInterceptorSetup from './services/auth-interceptor.js';
import services from './services/services.barrel.js'

import './styles/app.scss'

Vue.use(BootstrapVue);

library.add(
  faUserCircle,
  faTimes,
  faCloudUploadAlt,
  faTrashAlt,
  faPlusCircle,
  faPlusSquare,
  faExternalLinkAlt,
  faTimesCircle,
  faCommentDots,
  faImage,
  faEllipsisV,
  faCheck,
  faEdit,
  faShare,
  faStar,
  faUserPlus,
  faFileExport,
  faEye,
  faEyeSlash,
  faBug,
  faFilter,
  faChevronCircleUp,
  faInfoCircle,
  faEnvelope,
  faEnvelopeOpen,
  faEnvelopeOpenText
)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.prototype.$bus = bus;
Vue.prototype.$services = services;

Vue.config.productionTip = false

authInterceptorSetup()

new Vue({
  render: h => h(App),
  router,
  store,
  i18n
}).$mount('#app')
