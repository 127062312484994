<template>
  <div class="sticky-navbar">
    <b-navbar toggleable="lg" type="light" variant="light">
      <b-navbar-brand href="/">
        <img src="./assets/Logo-secondary.svg" />
      </b-navbar-brand>

      <div class="d-flex align-items-center collapsed-icons">
        <div class="support-icon clickable" style="margin-top: 2px">
          <font-awesome-icon
            v-if="isCollapsed"
            :icon="['fas', 'envelope']"
            size="lg"
            class="mr-3"
            @click="onSendSupportMessage"
          ></font-awesome-icon>
        </div>

        <b-navbar-toggle target="nav-collapse" style="margin-top: 1px">
          <font-awesome-icon icon="ellipsis-v"></font-awesome-icon>
        </b-navbar-toggle>
      </div>

      <b-collapse id="nav-collapse" class="nav-menu" is-nav>
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-form-select
            :value="selectedRecipeBank"
            @change="setSelectedRecipeBank"
            :options="selectOpts"
            class="recipe-bank-selector menu-text"
          ></b-form-select>
          <b-nav-item :to="'/menu'" :active="$route.name === 'menu'">{{
            $t("app.menuRoute")
          }}</b-nav-item>
          <b-nav-item
            :to="'/recipes'"
            :active="
              $route.name === 'recipes' || $route.name === 'recipe-details'
            "
            >{{ $t("app.recipeRoute") }}</b-nav-item
          >
          <b-nav-item
            v-if="showAdmin"
            :to="'/admin'"
            :active="$route.name.startsWith('admin')"
            >{{ $t("app.admin") }}</b-nav-item
          >

          <!-- Icons -->
          <div class="support-icon clickable">
            <font-awesome-icon
              v-if="!isCollapsed"
              :icon="['fas', 'envelope']"
              size="lg"
              @click="onSendSupportMessage"
            ></font-awesome-icon>
          </div>
          <b-nav-item-dropdown right>
            <!-- Using 'button-content' slot -->
            <template #button-content>
              <font-awesome-icon
                icon="user-circle"
                size="lg"
              ></font-awesome-icon>
            </template>
            <div class="user-details w-100">
              <div>{{ userName }}</div>
              <div style="font-size: 12px">{{ userEmail }}</div>
              <hr />
            </div>

            <b-dropdown-item :to="'/profile'" class="menu-text"
              >Min profil</b-dropdown-item
            >
            <b-dropdown-item @click="onSignOut" class="menu-text"
              >Logga ut</b-dropdown-item
            >
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      mySelectVal: null,
    };
  },
  computed: {
    showAdmin: function () {
      return this.$store.getters["auth/isAdmin"];
    },
    user: function () {
      return this.$store.getters["auth/currentUser"];
    },
    userName: function () {
      return this.user?.name ?? "";
    },
    userEmail: function () {
      return this.user?.email ?? "";
    },
    recipeBankSummary: function () {
      return this.$store.state.recipebanks.recipeBankSummary;
    },
    selectedRecipeBank: function () {
      return this.$store.state.recipebanks.selectedRecipeBank;
    },
    selectOpts: function () {
      var opts = [];
      this.recipeBankSummary.forEach((rb) => {
        opts.push({ value: { ...rb }, text: rb.name });
      });
      return opts;
    },
    isCollapsed: function () {
      return this.$store.getters["isCollapsedView"];
    },
  },
  methods: {
    onSignOut: function () {
      this.$bus.dispatch("logout");
    },
    setSelectedRecipeBank: function (rb) {
      this.$store.dispatch("recipebanks/setSelectedRecipeBank", {
        recipeBank: rb,
      });
    },
    onSendSupportMessage: function () {
      this.$bvModal.show("sendSupportMessage");
    },
  },
  mounted() {
    this.$store.dispatch("auth/getSessionUser");
  },
};
</script>

<style>
.navbar {
  box-shadow: 0 0.1px 4px grey !important;
}

.sticky-navbar {
  position: sticky;
  top: 0;
  z-index: 100;
}

.recipe-bank-selector {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.support-icon {
  color: rgba(0, 0, 0, 0.5);
  padding: 0.5rem;
}

.support-icon:hover {
  color: rgba(0, 0, 0, 0.7);
}

.user-details {
  padding: 4px 24px;
}

.navbar-toggler,
.navbar-toggler {
  border: none !important;
  color: #343a40 !important;
}

@media screen and (max-width: 991px) {
  .recipe-bank-selector {
    margin-top: 1rem;
    margin-bottom: 1.5rem;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .nav-menu {
    font-size: 20px;
    text-align: left;
  }

  .menu-text {
    font-size: 20px !important;
  }

  .support-icon {
    padding-left: 0;
    padding-right: 0;
    color: #343a40;
  }

  .support-icon:hover {
    color: #343a40;
  }
}
</style>