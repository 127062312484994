<template>
  <b-modal
    :id="modalId"
    title="Ändra receptbank"
    :visible="show"
    no-close-on-backdrop
    @hide="onClose"
    @ok="onSubmit"
    :cancel-title="$t('common.cancel')"
    ok-title="Skicka"
  >
    <p>
      Vill du dela med dig att en favorit? Vad kul! Fyll i email till personen
      du vill skicka '{{ recipe.name }}' till nedan
    </p>
    <form>
      <auto-complete-input
        :placeholder="'Fyll i email...'"
        :suggestions="members"
        v-model="member"
        :matcher="
          (entry, filter) => {
            lowerCaseFilter = filter.toLowerCase();
            return (
              entry.name.toLowerCase().includes(lowerCaseFilter) ||
              entry.email.toLowerCase().includes(lowerCaseFilter)
            );
          }
        "
      ></auto-complete-input>
    </form>
  </b-modal>
</template>

<script>
import UserService from "../../services/user.service.js";
import RecipeService from "../../services/recipes.service.js";
import AutoCompleteInput from "../controls/autocomple-input.vue";

export default {
  components: {
    AutoCompleteInput,
  },
  props: {
    modalId: {
      type: String,
      required: true,
    },
  },
  data: function () {
    return {
      show: false,
      recipe: {},
      member: { value: "" },
      users: [],
    };
  },
  computed: {
    members: function () {
      var members = [];
      this.users.forEach((u) => {
        members.push({
          value: u.email,
          display: `${u.name} (${u.email})`,
          name: u.name,
          email: u.email,
        });
      });
      return members;
    },
    user: function () {
      return this.$store.getters["auth/currentUser"];
    },
  },
  methods: {
    onClose: function () {
      this.member = { value: "" };
      this.show = false;
    },
    onSubmit: function () {
      RecipeService.share(this.member.value, this.recipe.id)
        .then(() => {
          this.$bus.dispatch("showToastSuccess", {
            title: "Recept delat",
            message: `Tjoho! Receptet skickades till '${this.email}'. Snyggt jobbat!`,
          });
        })
        .catch((e) => {
          console.log(e);
          this.$bus.dispatch("showToastFailure", {
            title: "Recept kunde inte delat",
            message: `Typiskt! Något satte käppar i hjulen. Ladda om sidan och försök igen eller kontakta supporten`,
          });
        });
    },
    fetchUsers: function () {
      UserService.searchUsers({
        text: "",
        page: 1,
        pageSize: 10000,
        sort: "name",
      })
        .then((result) => {
          this.users = result.users ?? [];
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  mounted() {
    this.$bus.on(this.modalId, (data) => {
      this.recipe = data.recipe;
      this.show = true;
    });
    this.fetchUsers();
  },
  beforeDestroy() {
    this.$bus.remove(this.modalId);
  },
};
</script>

<style>
</style>