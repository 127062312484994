<template>
  <b-modal
    :id="modalId"
    v-model="show"
    :title="$t('recipe.addRecipe')"
    :visible="show"
    no-close-on-backdrop
    no-close-on-esc
    @hide="onClose"
    @ok="onSubmit"
    :cancel-title="$t('common.cancel')"
    :ok-title="$t('common.save')"
  >
    <input
      type="text"
      class="form-control"
      :placeholder="$t('common.name')"
      v-model="recipe.name"
    />
    <input
      type="text"
      class="form-control mt-3"
      :placeholder="$t('common.source')"
      v-model="recipe.source"
    />
    <textarea
      v-model="recipe.notes"
      cols="30"
      rows="4"
      class="form-control mt-3"
      :placeholder="$t('common.notes')"
    ></textarea>
    <b-form-tags
      input-id="recipe-tags"
      v-model="recipe.tags"
      class="mt-3"
      :placeholder="$t('common.tags')"
      :invalidTagText="$t('recipe.invalidTag')"
      :duplicateTagText="$t('recipe.duplicateTag')"
    >
    </b-form-tags>
    <b-form-rating
      v-model="recipe.rating"
      size="lg"
      variant="variant"
      class="rating-viewer mt-4"
    ></b-form-rating>
  </b-modal>
</template>

<script>
import { recipeBankItemStatus } from "../../util/globals.js";
import RecipeService from "../../services/recipes.service.js";

export default {
  props: {
    modalId: {
      type: String,
      required: true,
    },
  },
  data: function () {
    return {
      show: false,
      loading: false,
      error: "",
      recipe: {},
    };
  },
  methods: {
    onClose: function (event) {
      this.recipe = this.emptyRecipe();
      if (event.trigger === "cancel" || event.trigger === "headerclose") {
        this.$emit("cancel");
      }
      this.show = false;
    },
    onSubmit: async function () {
      this.loading = true;
      RecipeService.updateRecipe(this.recipe)
        .then((recipe) => {
          this.$emit("recipeUpdated", { recipe: recipe });
        })
        .catch(() => {
          this.error = this.$t("recipe.unableSaveRecipe");
        })
        .finally(() => (this.loading = false));
    },
    emptyRecipe: function () {
      return {
        recipeBankID: this.$store.getters["recipebanks/selectedRecipeBank"],
        name: "",
        source: "",
        notes: "",
        tags: [],
        files: [],
        status: recipeBankItemStatus,
        rating: 0,
      };
    },
  },
  mounted() {
    this.recipe = this.emptyRecipe();
    this.$bus.on("showUpdateModal", (data) => {
      this.recipe = { ...data.recipe, status: recipeBankItemStatus };
      this.show = true;
    });
  },
  beforeDestroy() {
    this.$bus.remove("showUpdateModal");
  },
};
</script>

<style scoped>
.my-upload {
  border-color: #ced4da !important;
}

.my-upload > .icon {
  color: rgb(73, 80, 87) !important;
}
</style>