const urlRegex = new RegExp('^(http|https)://|www.');

export default {
  methods: {
    isUrlSource: function (source) {
      return this.isUrl(source)
    },
    getDomainName: function (url) {
      let domain = (new URL(url));
      domain = domain.hostname.replace('www.', '');
      return domain;
    },
    isUrl: function (url) {
      return urlRegex.test(url)
    }
  }
}