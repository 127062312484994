import axiosInstance from "./api";
import TokenService from "./token.service";
import EventBus from "../util/eventbus.js";

var refreshRequest = undefined;
var isRefreshing = false;

const setup = () => {
  axiosInstance.interceptors.request.use(
    (config) => {
      const token = TokenService.getLocalAccessToken();
      if (token && config.url !== "/tokens/authenticate") {
        config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;
      if (originalConfig.url !== "/tokens/authenticate" && originalConfig.url != "/tokens/refresh" && err.response) {
        if (err.response.status === 401) {
          if (!isRefreshing) {
            isRefreshing = true

            try {
              refreshRequest = axiosInstance.post("/tokens/refresh", {
                refreshToken: TokenService.getLocalRefreshToken(),
              });
              const rs = await refreshRequest

              TokenService.setSession(rs.data);
              return axiosInstance(originalConfig);
            } catch (_error) {
              EventBus.dispatch("logout")
              return Promise.reject(_error);
            } finally {
              isRefreshing = false;
            }
          }
          else {
            return refreshRequest.then(() => {
              const token = TokenService.getLocalAccessToken();
              err.config.headers['Authorization'] = 'Bearer ' + token;
              err.config.baseURL = undefined;
              return axiosInstance.request(err.config);
            })
          }
        }
      }

      return Promise.reject(err);
    }
  );
};

export default setup;