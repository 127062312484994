<template>
  <div class="app-page login-page">
    <div class="login-container app-card">
      <div :class="['login-card', { blurred: loading }]">
        <img src="../../assets/Logo-primary.svg" />
        <form>
          <div class="mt-5">
            <input
              type="text"
              class="form-control w-100"
              :placeholder="$t('common.email')"
              v-model="loginUser.email"
            />
          </div>
          <div class="mt-4">
            <input
              type="password"
              class="form-control w-100"
              :placeholder="$t('common.password')"
              v-model="loginUser.password"
              autocomplete
            />
          </div>
          <div class="mt-3 text-left">
            <router-link to="/request-password-reset">{{
              $t("login.forgotPassword")
            }}</router-link>
          </div>
          <div class="mt-4 pt-3">
            <button
              class="btn btn-secondary w-100"
              type="submit"
              @click.prevent="onSubmit"
            >
              {{ $t("login.login") }}
            </button>
            <div v-if="error" class="invalid-feedback d-block text-left">
              {{ error }}
            </div>
          </div>
        </form>
      </div>
      <div v-if="loading" class="login-loading-hud app-card">
        <div class="loader awesome-spin"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      loginUser: {},
      loading: false,
      error: "",
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/");
    }
  },
  methods: {
    newUser: function () {
      return {
        email: "",
        password: "",
      };
    },
    onSubmit: function () {
      this.error = "";
      if (this.loginUser.email && this.loginUser.password) {
        this.loading = true;
        var fetchPromise = this.$store
          .dispatch("auth/login", this.loginUser)
          .catch(() => {
            this.error = this.$t("login.loginError");
          });
        var waitPromise = new Promise((resolve) => setTimeout(resolve, 0));

        Promise.allSettled([fetchPromise, waitPromise])
          .then(() => {
            if (this.$store.state.auth.status.loggedIn) {
              var redirect = this.$route.query.redirect
                ? this.$route.query.redirect
                : "/";
              this.$router.replace({ path: redirect });
            }
          })
          .catch(() => {
            this.error = this.$t("login.loginError");
          })
          .finally(() => (this.loading = false));
      } else {
        this.error = this.$t("login.loginError");
      }
    },
  },
  mounted() {
    this.user = this.newUser();
  },
};
</script>
