<template>
  <div class="container profile-list">
    <personal-info></personal-info>
    <recipe-banks-info></recipe-banks-info>
    <shared-recipes></shared-recipes>
  </div>
</template>

<script>
import PersonalInfo from "../widgets/profile-personal-info.vue";
import RecipeBanksInfo from "../widgets/profile-recipe-banks.vue";
import SharedRecipes from "../widgets/profile-shared-recipes.vue";

export default {
  components: {
    PersonalInfo,
    RecipeBanksInfo,
    SharedRecipes,
  },
  data: function () {
    return {
      loading: false,
    };
  },
  computed: {},
  methods: {},
  mounted() {
    this.loading = true;
    var getUser = this.$store.dispatch("auth/getSessionUser");
    var getRecipeBanks = this.$store.dispatch("recipebanks/fetchRecipeBank");

    Promise.all([getUser, getRecipeBanks]).finally(
      () => (this.loading = false)
    );
  },
};
</script>

<style>
.profile-card {
  width: 100%;
  padding: 1.5rem 0.75rem;
  background-color: #fcfcfc;
  border-radius: 0.15rem;
  box-shadow: 0.5px 1px 2px grey;
  text-align: left;
}

.profile-section-header {
  font-size: 1.75rem;
  font-weight: bold;
}

.profile-data-header {
  font-size: 14px;
  font-weight: bolder;
}

.personal-data {
}
</style>

<style scoped>
.profile-list > :not(:first-child) {
  margin-top: 1rem;
}
</style>