<template>
  <div class="recipe-details-box">
    <div class="recipe-details-container">
      <update-full-recipe-modal
        :modalId="'fullUpdateRecipeModal'"
        @recipeUpdated="onRecipeUpdated"
      ></update-full-recipe-modal>
      <share-recipe-modal :modalId="'shareRecipe_details'"></share-recipe-modal>
      <div class="recipe-details-body">
        <b-skeleton-wrapper :loading="loading" class="w-100">
          <template #loading>
            <div class="w-100">
              <b-skeleton width="90%" class="skeleton-header"></b-skeleton>
              <br />
              <b-skeleton width="30%"></b-skeleton>
              <b-skeleton width="55%" class="skeleton-text"></b-skeleton>
              <br />
              <b-skeleton width="30%"></b-skeleton>
              <b-skeleton width="40%" class="skeleton-text"></b-skeleton>
              <br />
              <b-skeleton width="30%"></b-skeleton>
              <b-skeleton width="80%" class="skeleton-text"></b-skeleton>
              <br />
              <b-skeleton width="30%"></b-skeleton>
              <b-skeleton width="60%" class="skeleton-text"></b-skeleton>
              <b-skeleton width="40%" class="skeleton-text"></b-skeleton>
            </div>
          </template>
          <div class="recipe-details-title-row">
            <h1 style="font-weight: bold">{{ recipe.name }}</h1>
          </div>

          <div v-if="recipe.source">
            <div class="recipe-details-header mt-4">Källa</div>
            <div v-if="isUrlSource(recipe.source)">
              <a @click.stop="onSourceClicked" class="source-link">
                <span class="recipe-details-bodytext recipe-source">
                  <font-awesome-icon
                    icon="external-link-alt"
                    size="xl"
                  ></font-awesome-icon>
                  {{ getDomainName(recipe.source) }}
                </span>
              </a>
            </div>
            <div v-else>
              <div
                class="recipe-details-bodytext recipe-source"
                v-if="recipe.source"
              >
                {{ recipe.source }}
              </div>
            </div>
          </div>

          <div v-if="recipe.notes">
            <div class="recipe-details-header mt-4">Anteckningar</div>
            <div v-if="recipe.notes" class="recipe-details-bodytext">
              {{ recipe.notes }}
            </div>
          </div>

          <div v-if="recipe.tags && recipe.tags.length > 0">
            <div class="recipe-details-header mt-4">Taggar</div>
            <div class="w-100 recipe-tags">
              <b-badge
                v-for="tag in recipe.tags"
                :key="tag"
                variant="dark"
                class="mr-1 mt-1"
                >{{ tag }}</b-badge
              >
            </div>
          </div>

          <div v-if="recipe.images">
            <div class="recipe-details-header mt-4">Bilder</div>
            <div v-if="isWideView" class="container mt-2 pl-0">
              <div class="row">
                <div
                  class="recipe-image-container col-4"
                  v-for="(i, index) in images"
                  :key="index"
                >
                  <img
                    :src="i.data"
                    class="recipe-image"
                    @click="navigateToImage(i.url)"
                  />
                </div>
              </div>
            </div>
            <div v-else class="carousel-container mt-2">
              <b-carousel
                id="recipe-image-carousel"
                :interval="0"
                :controls="recipe.images.length > 1"
                :indicators="recipe.images.length > 1"
                style="max-width: 100%; max-height: 100%"
              >
                <b-carousel-slide
                  v-for="(i, index) in images"
                  :key="index"
                  :img-src="i.data"
                  class="carousel-image"
                ></b-carousel-slide>
              </b-carousel>
            </div>
          </div>

          <div class="mt-4">
            <div class="recipe-details-header mb-2">Betyg</div>
            <div v-if="recipe.rating">
              <b-form-rating
                v-model="recipe.rating"
                size="lg"
                variant="variant"
                class="rating-viewer"
                readonly
              ></b-form-rating>
            </div>
            <div v-else class="empty-rating">
              Receptet har inte fått något betyg än, skynda dig och lägg till
              ett!
            </div>
          </div>

          <div class="recipe-details-header mt-4">Tillagd av</div>
          <div class="recipe-details-bodytext">
            {{ recipe.userName }}
          </div>
          <div class="recipe-details-bodytext">
            {{ toDate(recipe.createdAt) }}
          </div>
          <div class="recipe-details-actions mt-3 pl-0">
            <font-awesome-icon
              icon="edit"
              size="lg"
              class="mr-4 clickable"
              @click="onEditClicked"
            ></font-awesome-icon>
            <font-awesome-icon
              icon="share"
              class="mr-4 clickable"
              size="lg"
              @click="onShareRecipe"
            ></font-awesome-icon>
            <img
              src="../../assets/menu-add.svg"
              class="mr-4 clickable"
              @click="onAddToMenu"
            />
            <font-awesome-icon
              icon="trash-alt"
              size="lg"
              class="ml-1 text-danger clickable"
              @click="onDeleteClicked"
            ></font-awesome-icon>
          </div>
        </b-skeleton-wrapper>
      </div>
    </div>
  </div>
</template>

<script>
import MenuService from "../../services/menu.service.js";
import ImageService from "../../services/images.service.js";
import SourceParserMixin from "../mixins/source-parser-mixin.js";
import UpdateFullRecipeModal from "../modals/full-update-recipe-modal.vue";
import ShareRecipeModal from "../modals/share-recipe-modal.vue";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";

export default {
  components: {
    UpdateFullRecipeModal,
    ShareRecipeModal,
  },
  mixins: [SourceParserMixin],
  data: function () {
    return {
      loading: false,
      error: "",
      images: [],
    };
  },
  computed: {
    recipe: function () {
      return this.$store.state.recipes.selectedRecipe;
    },
    isWideView: function () {
      return this.$store.state.viewPort.width > 575;
    },
  },
  methods: {
    onAddToMenu: function () {
      this.$bvModal
        .msgBoxConfirm(`Lägg till '${this.recipe.name}' i menyn?`, {
          buttonSize: "sm",
          okVariant: "primary",
          okTitle: "Lägg till",
          cancelTitle: "Avbryt",
        })
        .then((result) => {
          if (result) {
            MenuService.addRecipe(
              this.$store.getters["recipebanks/selectedRecipeBankMenuId"],
              this.recipe.id
            )
              .then(() => {
                this.$bus.dispatch("showToastSuccess", {
                  title: "Recept tillagt",
                  message: `'${this.recipe.name}' tillagt i menyn! Hoppas det smakar!`,
                });
              })
              .catch(() => {
                this.$bus.dispatch("showToastFailure", {
                  title: "Recept lades inte till",
                  message: `Ojdå! '${this.recipe.name}' kunde inte läggas till i menyn. Försök igen eller kontakta supporten'`,
                });
              });
          }
          return Promise.resolve();
        });
    },
    onShareRecipe: function () {
      this.$bus.dispatch("shareRecipe_details", { recipe: this.recipe });
    },
    onSourceClicked: function () {
      window.location.href = this.recipe.source;
    },
    toDate: function (isoDate) {
      return moment(isoDate).format("YYYY-MM-DD");
    },
    parseUrl: function (url) {
      if (url.startsWith("localhost")) {
        return "http://" + url;
      }
      return url;
    },
    parseData: function (url, base64Data) {
      if (url.endsWith("png")) {
        return `data:image/png;base64,${base64Data}`;
      }
      return `data:image/jpeg;base64,${base64Data}`;
    },
    navigateToImage: function (url) {
      window.location.href = url;
    },
    onDeleteClicked: function () {
      this.$bvModal
        .msgBoxConfirm(
          `Är du säkert på att du vill ta bort '${this.recipe.name}'`,
          {
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "Ta bort",
            cancelTitle: "Avbryt",
          }
        )
        .then((result) => {
          if (result) {
            return this.$store.dispatch("recipes/remove", {
              recipe: { ...this.recipe },
            });
          }
        })
        .then((success) => {
          if (success) {
            this.$router.go(-1);
          }
        })
        .catch((e) => {
          console.log(e);
          this.$bus.dispatch("showToastFailure", {
            title: "Recept kunde inte tas bort",
            message: `Ojdå! '${this.recipe.name}' kunde inte tas bort. Uppdatera sidan och försök igen eller kontakta supporten'`,
          });
        });
    },
    onEditClicked: function () {
      this.$bus.dispatch("showFullUpdateModal", { recipe: { ...this.recipe } });
    },
    onRecipeUpdated: function () {
      this.loadRecipe(true);
    },
    loadRecipe: function (loadImages) {
      this.loading = true;
      this.images = [];
      this.$store
        .dispatch("recipes/selectRecipe", { id: this.$route.params.id })
        .then((recipe) => {
          if (recipe.images && recipe.images.length > 0 && loadImages) {
            console.log(loadImages);
            console.log("loading images");
            recipe.files = [];
            recipe.images.forEach((url, index) =>
              ImageService.get(url).then((data) => {
                var blob = new Blob([data]);
                recipe.files.push({
                  file: new File([blob], `image_${index}`),
                  ref: uuidv4(),
                  url: url,
                });
                const objectURL = URL.createObjectURL(blob);
                this.images.push({
                  url: url,
                  data: objectURL,
                });
              })
            );
          }
          return Promise.resolve(recipe);
        })
        .catch((e) => console.log(e))
        .finally(() => setTimeout(() => (this.loading = false), 400));
    },
  },
  mounted() {
    this.loadRecipe(true);
  },
  beforeDestroy() {
    this.$store.commit("recipes/setSelectedRecipe", {});
    this.images = [];
  },
};
</script>

<style scoped>
.recipe-details-box {
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.recipe-details-container {
  background-image: url("../../assets/wooden-background.jpg");
  background-color: #fcfcfc;
  max-width: 600px;
  width: 100%;
  text-align: left;
  padding: 1rem;
  box-shadow: 3px 3px 10px 2px grey;
  border-radius: 0.75rem;
}

.recipe-details-body {
  background-image: url("../../assets/white-paper.jpg");
  border-radius: 0.15rem;
  width: 100%;
  height: 100%;
  text-align: left;
  padding: 1.5rem;
  box-shadow: 0.5px 0.5px 4px 0px grey;
}

.recipe-details-title-row {
  display: flex;
  justify-content: space-between;
}

.recipe-details-actions {
  padding-top: 0.7rem;
  min-width: 136px;
  padding-left: 1rem;
}

.recipe-details-header {
  font-size: 14px;
  font-weight: bolder;
}

.recipe-details-bodytext {
  font-size: 1.25rem;
}

.recipe-details-val-missing {
  font-style: italic;
}

.recipe-image {
  width: inherit;
  border-radius: 0.15rem;
  box-shadow: 0 1px 4px grey;
}

.carousel-image >>> img {
  border-radius: 0.15rem;
}

.carousel-container {
  box-shadow: 0 1px 4px grey;
  border-radius: 0.15rem;
}

.empty-rating {
  font-style: italic;
}

.skeleton-header {
  height: 2.5rem !important;
}

.skeleton-text {
  height: 1.25rem !important;
}
</style>