import api from "./api.js"

class MessagesService {
  sendAddUserRequest(userData) {
    return api.post("messages/userrequest", userData)
      .then(response => response.data);
  }
  getAdminMessages() {
    return api.get("/admin/messages")
      .then(response => response.data?.messages)
  }
  get(id) {
    return api.get(`/messages/${id}`)
      .then(response => response.data?.message)
  }
  markMessageAsRead(id) {
    return api.patch(`/messages/${id}`, { isRead: true })
      .then(response => response.data?.message)
  }
  delete(id) {
    return api.delete(`/messages/${id}`)
  }
  sendSupportMessage(data) {
    return api.post("/message/support-message", { message: data.message })
  }
}

export default new MessagesService();