import RecipeBankService from '../../services/recipebank.service.js';
import { store, load, currentRecipeBankIdKey } from '../../util/local-storage.js';

const initialState = () => {
  return {
    recipeBankSummary: [],
    selectedRecipeBank: null
  }
}

export const recipebanks = {
  namespaced: true,
  state: initialState(),
  getters: {
    recipebanksCount: state => state.recipeBankSummary.length,
    selectedRecipeBankId: state => state.selectedRecipeBank?.id,
    selectedRecipeBankMenuId: state => state.selectedRecipeBank?.menuID
  },
  actions: {
    setInitState({ commit }) {
      commit("setInitState", initialState())
    },
    fetchRecipeBank({ commit }) {
      return RecipeBankService.getRecipeBankSummary()
        .then(
          recipeBankSummary => {
            commit('setRecipeBanks', recipeBankSummary)

            var selectedRecipeBank = null;
            var recipeBankId = load(currentRecipeBankIdKey)
            if (recipeBankId) {
              selectedRecipeBank = recipeBankSummary.find(rb => rb.id === parseInt(recipeBankId))
            } else {
              selectedRecipeBank = recipeBankSummary.find(rb => rb.default);
            }


            if (!selectedRecipeBank && recipeBankSummary.length > 0) {
              selectedRecipeBank = recipeBankSummary[0]
            }
            commit('setSelectedRecipeBank', selectedRecipeBank);

            return Promise.resolve(recipeBankSummary)
          },
          error => {
            return Promise.reject(error);
          }
        )
    },
    create({ dispatch }, recipebank) {
      return RecipeBankService.createRecipeBank(
        {
          Name: recipebank.name,
          Collaborators: recipebank.collaborators
        })
        .then(
          recipebank => {
            return dispatch('fetchRecipeBank').then(() => Promise.resolve(recipebank));
          },
          error => {
            return Promise.reject(error);
          }
        )
    },
    removeRecipeBank({ dispatch }, data) {
      console.log(data)
      return RecipeBankService.removeRecipeBank(data.recipeBankId)
        .then(
          () => dispatch('fetchRecipeBank'),
          error => Promise.reject(error)
        )
    },
    setSelectedRecipeBank({ commit }, data) {
      store(currentRecipeBankIdKey, data.recipeBank.id);
      commit("setSelectedRecipeBank", data.recipeBank);
    }
  },
  mutations: {
    setInitState(state, initialState) {
      Object.assign(state, initialState);
    },
    setRecipeBanks(state, recipeBankSummary) {
      state.recipeBankSummary = [...recipeBankSummary]
    },
    setSelectedRecipeBank(state, recipeBank) {
      state.selectedRecipeBank = { ...recipeBank };
    }
  }
};