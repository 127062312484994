<template>
  <div>
    <div class="w-100 font-italic">
      {{ ratingMessage }}
    </div>
    <div class="range-slider">
      <input
        type="range"
        min="0"
        max="5"
        step="1"
        :value="minValue"
        @input="setMin"
        :disabled="disable"
      />
      <input
        type="range"
        min="0"
        max="5"
        step="1"
        :value="maxValue"
        @input="setMax"
        :disabled="disable"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
    disable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    minValue: function () {
      if (this.disable) return 0;
      return this.value.min;
    },
    maxValue: function () {
      if (this.disable) return 5;
      return this.value.max;
    },
    ratingMessage: function () {
      if (this.disable)
        return "Omdömesfiltrering är ej aktiv när slussen visas";
      else if (this.value.min === 0 && this.value.max === 5)
        return "Visar alla recept, med eller utan omdöme";
      else if (this.value.min === 0 && this.value.max < 5)
        return `Visar recept med maximalt ${this.value.max} i omdöme, recept utan omdöme inkluderade`;
      else if (this.value.min === 1 && this.value.max === 5)
        return "Visar alla recept med ett omdöme";
      else if (this.value.min === this.value.max)
        return `Visar recept med ${this.value.min} i omdöme`;
      else
        return `Visar recept med omdöme mellan ${this.value.min} och ${this.value.max}`;
    },
  },
  methods: {
    setMin: function (e) {
      var min = parseInt(e.target.value);
      if (min < 0) min = 0;
      if (min <= this.value.max)
        this.$emit("input", { min: min, max: this.value.max });
      else this.$emit("input", { min: this.value.max, max: this.value.max });
    },
    setMax: function (e) {
      var max = parseInt(e.target.value);
      if (max > 5) max = 5;
      if (max >= this.value.min)
        this.$emit("input", { min: this.value.min, max: max });
      else this.$emit("input", { min: this.value.min, max: this.value.min });
    },
  },
};
</script>

<style scoped>
.range-slider {
  margin: auto;
  text-align: center;
  position: relative;
  height: 2rem;
}

.range-slider input[type="range"] {
  position: absolute;
  left: 0;
  bottom: 0;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"]:invalid,
input[type="number"]:out-of-range {
  border: 2px solid #ff6347;
}

input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
}

input[type="range"]:focus {
  outline: none;
}

input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #17a2b8;
}

input[type="range"]:focus::-webkit-slider-runnable-track:disabled {
  background: #fdfdfd;
}

input[type="range"]:focus::-ms-fill-lower {
  background: #17a2b8;
}

input[type="range"]:focus::-ms-fill-lower:disabled {
  background: #fdfdfd;
}

input[type="range"]:focus::-ms-fill-upper {
  background: #17a2b8;
}

input[type="range"]:focus::-ms-fill-upper {
  background: #fdfdfd;
}

input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  background: #17a2b8;
  border-radius: 1px;
  box-shadow: none;
  border: 0;
}

input[type="range"]::-webkit-slider-runnable-track:disabled {
  background: #fdfdfd;
}

input[type="range"]::-webkit-slider-thumb {
  z-index: 2;
  position: relative;
  box-shadow: 0px 0px 0px #000;
  border: 1px solid #17a2b8;
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: #a1d0ff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -7px;
}

input[type="range"]::-webkit-slider-thumb:disabled {
  background: #fdfdfd;
}
</style>