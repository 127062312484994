<template>
  <b-modal
    :id="modalId"
    title="Lägg till användare till receptbank"
    :visible="show"
    no-close-on-backdrop
    @hide="onClose"
    @ok="onSubmit"
    :cancel-title="$t('common.cancel')"
    ok-title="Lägg till"
  >
    <input
      type="text"
      ref="addUserToRecipeBankEmail"
      class="form-control mt-3"
      placeholder="Email"
      v-model="userEmail"
    />
  </b-modal>
</template>

<script>
import RecipeBankService from "../../services/recipebank.service.js";

export default {
  props: {
    modalId: {
      type: String,
      required: true,
    },
  },
  data: function () {
    return {
      show: false,
      loading: false,
      userEmail: "",
      recipeBank: {},
    };
  },
  methods: {
    emptyUser: function () {
      return "";
    },
    onClose: function () {
      this.userEmail = "";
      this.show = false;
    },
    onSubmit: function () {
      this.loading = true;
      var userEmail = this.userEmail;
      RecipeBankService.addMember(this.recipeBank.Id, this.userEmail)
        .then(() => this.store.dispatch("recipebanks/fetchRecipeBank"))
        .catch((e) => {
          console.log(e);
          return this.$bus.dispatch("showToastFailure", {
            title: "Kunde inte lägga till användare till receptbank",
            message: `Ojdå! '${userEmail}' kunde inte läggas till i receptbanken '${this.recipeBank.name}'. Kontakta supporten om du är säker på att användaren finns, annars kan du begära att användaren läggs till först`,
          });
        })
        .finally(() => (this.loading = false));
    },
  },
  mounted() {
    this.userEmail = this.emptyUser();
    this.$bus.on("showAddRecipeBankUserModal", (data) => {
      this.recipeBank = { ...data.recipeBank };
      this.show = true;
    });
  },
  beforeDestroy() {
    this.$bus.remove("showAddRecipeBankUserModal");
  },
};
</script>

<style>
</style>