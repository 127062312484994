<template>
  <div class="w-100 text-left">
    <div class="user-request-message-title">
      Begäran att lägga till '{{ message.data.name }}'
    </div>
    <div>
      Skapad av: {{ message.creator.name }} ({{ message.creator.email }})
    </div>
    <div>
      {{ toDateFormat(message.creator.createdAt) }}
    </div>
    <hr />
    <div>En användare med följande uppgifter önskas läggas till:</div>
    <div class="mt-3">
      Namn:<span class="ml-3">{{ message.data.name }}</span>
    </div>
    <div class="mb-4">
      Email: <span class="ml-3">{{ message.data.email }}</span>
    </div>
    <button class="btn btn-primary btn-sm" @click="onAddUserClicked">
      Skapa
    </button>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    message: {
      type: Object,
      required: true,
    },
  },
  computed: {
    messageId: function () {
      return this.$route.params.id;
    },
  },
  methods: {
    onAddUserClicked: function () {
      this.$bus.dispatch("adminAddUser_messages", {
        user: {
          name: this.message?.data?.name,
          email: this.message?.data?.email,
          password: "",
        },
      });
    },
    toDateFormat: function (isoDate) {
      return moment(isoDate).format("YYYY-MM-DD");
    },
  },
};
</script>

<style scoped>
.user-request-message-title {
  font-size: 1.75rem;
  font-weight: bold;
}

.user-request-message-subtitle {
  display: flex;
}
</style>