<template>
  <b-modal
    :id="modalId"
    title="Skapa ny användare"
    :visible="show"
    no-close-on-backdrop
    @hide="onClose"
    @ok="onSubmit"
    :cancel-title="$t('common.cancel')"
    ok-title="Skapa"
  >
    <form>
      <input
        type="text"
        class="form-control"
        :placeholder="$t('common.name')"
        v-model="user.name"
        autocomplete="name"
      />
      <input
        type="text"
        class="form-control mt-3"
        placeholder="Email"
        v-model="user.email"
        autocomplete="email"
      />
    </form>
  </b-modal>
</template>

<script>
import UserService from "../../services/user.service.js";

export default {
  props: {
    modalId: {
      type: String,
      required: true,
    },
  },
  data: function () {
    return {
      show: false,
      loading: false,
      user: {},
    };
  },
  methods: {
    emptyUser: function () {
      return { name: "", email: "" };
    },
    onClose: function () {
      this.user = this.emptyUser();
      this.show = false;
    },
    onSubmit: function () {
      this.loading = true;
      UserService.createUser({ ...this.user })
        .then(() => {
          this.$bus.dispatch("showToastSuccess", {
            title: "Användare tillagd",
            message: `Tjoho! En ny användare har skapats och en aktiveringslänk har skickats till '${this.user.email}`,
          });
          this.$emit("userAdded");
          this.$bvModal.hide(this.modalId);
        })
        .catch((err) => {
          console.log(err);
          this.$bus.dispatch("showToastFailure", {
            title: "Användare kunde inte läggas till",
            message: `Aj då! Användaren kunde inte läggas till. Kolla loggarna för vidare information`,
          });
        })
        .finally(() => (this.loading = false));
    },
  },
  mounted() {
    this.user = this.emptyUser();
    this.$bus.on(this.modalId, (data) => {
      this.user = data.user;
      this.show = true;
    });
  },
  beforeDestroy() {
    this.$bus.remove(this.modalID);
  },
};
</script>

<style>
</style>