<template>
  <b-modal
    :id="modalId"
    title="Supportmeddelande"
    :visible="show"
    no-close-on-backdrop
    @hide="onClose"
    @ok="onSubmit"
    :cancel-title="$t('common.cancel')"
    ok-title="Skicka"
  >
    <p>
      Har du någon fråga eller förslag på förbättring? Skriv ett meddeland nedan
      så ska vi se vad vi kan göra.
    </p>
    <textarea
      name="support-message"
      id="support-message"
      rows="10"
      class="w-100 form-control"
      v-model="message"
    ></textarea>
  </b-modal>
</template>

<script>
import MessageService from "../../services/messages.service.js";

export default {
  props: {
    modalId: {
      type: String,
      required: true,
    },
  },
  data: function () {
    return {
      message: "",
      show: false,
    };
  },
  methods: {
    onSubmit: function () {
      var msg = this.message;
      MessageService.sendSupportMessage({ message: msg })
        .then(() => {
          this.$bus.dispatch("showToastSuccess", {
            title: "Meddelandet skickat",
            message: `Ditt meddelande har mottagits framgångsrikt`,
          });
        })
        .catch((e) => {
          console.log(e);
          this.$bus.dispatch("showToastFailure", {
            title: "Meddelandet kunde inte skickas",
            message: `Ajdå, något gick snett och ditt meddelande kunde inte skickas. Prova igen senare.`,
          });
        });
    },
    onClose: function () {
      this.message = "";
    },
  },
};
</script>

<style>
</style>