<template>
  <div class="app-page login-page">
    <div class="login-container password-container app-card">
      <div :class="['login-card', { blurred: loading }]">
        <img src="../../assets/Logo-primary.svg" />
        <div class="text-left mt-2">
          <p>Ange ditt nya lösenord nedan</p>
        </div>
        <form>
          <div class="mt-4">
            <div class="input-group mt-3">
              <input
                :type="passwordInputType"
                v-model="password"
                class="form-control"
                placeholder="Lösenord"
                aria-describedby="user-input2"
                autocomplete="current-password"
              />
              <div class="input-group-append">
                <span
                  class="input-group-text"
                  id="user-input1"
                  style="min-width: 3rem"
                  ><font-awesome-icon
                    :icon="passwordInputIcon"
                    class="clickable"
                    @click="showPassword = !showPassword"
                  ></font-awesome-icon
                ></span>
              </div>
            </div>
          </div>
          <div class="mt-3 text-left">
            <router-link to="/login">Tillbaka till inloggning</router-link>
          </div>
          <div class="mt-4 pt-3">
            <button
              class="btn btn-secondary w-100"
              type="submit"
              @click.prevent="onSubmit"
            >
              Spara
            </button>
            <div v-if="error" class="invalid-feedback d-block text-left">
              {{ error }}
            </div>
          </div>
        </form>
      </div>
      <div v-if="loading" class="login-loading-hud app-card">
        <div class="loader awesome-spin"></div>
      </div>
    </div>
  </div>
</template>

<script>
import UserService from "../../services/user.service.js";
export default {
  data: function () {
    return {
      password: "",
      showPassword: false,
      loading: false,
      error: "",
    };
  },
  computed: {
    passwordInputIcon: function () {
      return this.showPassword ? "eye-slash" : "eye";
    },
    passwordInputType: function () {
      return this.showPassword ? "text" : "password";
    },
    token: function () {
      return this.$route.query.token;
    },
  },
  methods: {
    onSubmit: function () {
      this.loading = true;
      UserService.updatePassword(this.token, this.password)
        .then(() => {
          this.$bus.dispatch("showToastSuccess", {
            title: "Lösenord återställt",
            message: `Lösenordet återställdes. Du kan nu logga in med det nya lösenordet`,
          });
        })
        .catch((e) => {
          console.log(e);
          this.$bus.dispatch("showToastFailure", {
            title: "Lösenordet kunde inte återställas",
            message: `Något gick fel. Prova ett starkare lösenord eller kontakta supporten`,
          });
        })
        .finally(() => {
          this.loading = false;
          this.password = "";
        });
    },
  },
};
</script>

<style scoped>
.password-container {
  max-width: 400px;
}
</style>