<template>
  <div class="menu-box">
    <div class="menu-container">
      <div class="menu text-center">
        <div class="menu-header">
          <h1 style="font-weight: bold">{{ $t("menu.menuTitle") }}</h1>
          <font-awesome-icon
            v-if="showAddIcon"
            :icon="['far', 'plus-square']"
            size="2x"
            class="clickable"
            @click="onAddClicked"
          ></font-awesome-icon>
        </div>
        <div class="menu-body mt-5 mb-4">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showAddIcon: Boolean,
  },
  methods: {
    onAddClicked: function () {
      this.$emit("addClicked");
    },
  },
};
</script>

<style>
.menu-box {
  width: 100%;
  display: flex;
  justify-content: center;
}

.menu-container {
  background-image: url("../../assets/wooden-background.jpg");
  border-radius: 0.75rem;
  max-width: 600px;
  width: 100%;
  padding: 1rem;
  box-shadow: 3px 3px 10px 2px grey;
}
.menu {
  background-image: url("../../assets/white-paper.jpg");
  border-radius: 0.15rem;
  width: 100%;
  height: 100%;
  text-align: left;
  padding: 1.5rem;
  box-shadow: 0.5px 0.5px 4px 0px grey;
}

.menu-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
</style>