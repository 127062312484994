<template>
  <div class="container app-page-card">
    <request-user-modal modalId="requestNewUserModal"></request-user-modal>
    <edit-user-modal
      modalId="editOwnUserModal"
      @userUpdated="onUserUpdated"
    ></edit-user-modal>
    <div class="default-text">
      <div class="profile-section-header">Personlig information</div>
      <div class="row">
        <div class="col-12 col-sm-6 col-md-4 mt-4">
          <div class="profile-data-header">Namn</div>
          <div class="mt-2 profile-data">{{ user.name }}</div>
        </div>
        <div class="col-12 col-sm-6 col-md-4 mt-4">
          <div class="profile-data-header">Email</div>
          <div class="mt-2 profile-data">{{ user.email }}</div>
        </div>
        <div class="col-12 col-sm-6 col-md-4 mt-4">
          <div class="profile-data-header">Medlem sedan</div>
          <div class="mt-2 profile-data">
            {{ getMemberSince(user.createdBy) }}
          </div>
        </div>
      </div>
    </div>
    <hr />
    <div class="actions">
      <div class="row">
        <div class="col-12">
          <div class="row">
            <div class="col-12 col-sm-4">
              <button
                class="btn btn-sm btn-primary w-100"
                @click="onEditOwnUser"
              >
                Redigera
              </button>
            </div>
            <div :class="['col-12', 'col-sm-4', { 'mt-2': !isWideView }]">
              <button
                class="btn btn-sm btn-secondary w-100"
                @click="onRequestNewUser"
              >
                Begär ny medlem
              </button>
            </div>
            <div :class="['col-12', 'col-sm-4', { 'mt-2': !isWideView }]">
              <button class="btn btn-sm btn-danger w-100" @click="onRemoveMe">
                Ta bort mig
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import RequestUserModal from "../modals/request-user-modal.vue";
import UserService from "../../services/user.service.js";
import EditUserModal from "../modals/edit-user-modal.vue";
export default {
  components: {
    RequestUserModal,
    EditUserModal,
  },
  computed: {
    user: function () {
      return this.$store.getters["auth/currentUser"];
    },
    isMediumView: function () {
      return this.$store.getters["isMediumView"];
    },
    isWideView: function () {
      return this.$store.getters["isWideView"];
    },
  },
  methods: {
    getMemberSince: function (textDate) {
      return moment(textDate).format("YYYY-MM-DD");
    },
    onRequestNewUser: function () {
      this.$bvModal.show("requestNewUserModal");
    },
    onEditOwnUser: function () {
      this.$bus.dispatch("showEditUserModal", { user: this.user });
    },
    onRemoveMe: function () {
      this.$bvModal
        .msgBoxConfirm(`Är du säkert på att du vill ta bort din användare?`, {
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Ta bort",
          cancelTitle: "Avbryt",
        })
        .then((result) => {
          if (result) {
            return UserService.deleteUser()
              .then(() => this.$store.commit("auth/logout"))
              .then(() => this.$router.push("/login"));
          }
          return Promise.resolve();
        })
        .catch((e) => {
          console.log(e);
          this.$bus.dispatch("showToastFailure", {
            title: "Något gick fel!",
            message: `Ojdå! Vi kunde inte ta bort din användare. Uppdatera sidan och försök igen eller kontakta supporten'`,
          });
        });
    },
    onUserUpdated: function () {
      this.$store.dispatch("auth/getSessionUser");
    },
  },
};
</script>