<template>
  <div class="w-100">
    <div v-if="loading" class="login-loading-hud p-2">
      <div class="loader awesome-spin"></div>
    </div>
    <div v-if="message.id > 0">
      <user-request-message :message="message"></user-request-message>
    </div>
  </div>
</template>

<script>
import MessageService from "../../services/messages.service.js";
import UserRequestMessage from "../widgets/messages/user-request-message.vue";

export default {
  components: {
    UserRequestMessage,
  },
  data: function () {
    return {
      message: {},
      loading: false,
      fetchMessageError: false,
    };
  },
  computed: {
    messageId: function () {
      return this.$route.params.id;
    },
  },
  methods: {
    getMessage: function () {
      this.loading = true;
      MessageService.get(this.messageId)
        .then((message) => {
          this.message = message;
          if (!message.isRead) {
            return MessageService.markMessageAsRead(message.id).then(() => {
              this.message.isRead = true;
              this.$bus.dispatch("markRead", { messageId: this.message.id });
            });
          }
        })
        .catch((e) => {
          console.log(e);
          this.fetchMessageError = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.getMessage();
  },
  watch: {
    messageId(newVal, oldVal) {
      if (newVal != oldVal) {
        this.getMessage();
      }
    },
  },
};
</script>

<style>
</style>