<template>
  <div class="container app-page-card">
    <import-shared-recipes-modal
      modalId="inspectSharedRecipesModal"
      @recipeShareHandled="onRecipeShareHandled"
    ></import-shared-recipes-modal>
    <div>
      <div class="profile-section-header">Delade recept</div>
      <div
        v-if="initializationError"
        class="mt-3 d-flex flex-column align-items-center"
      >
        <font-awesome-icon icon="bug" size="3x"></font-awesome-icon>
        <div class="mt-3">
          Ajdå, något gick åt skogen när delade recept skulle hämtas
        </div>
      </div>
      <div v-else-if="noShares" class="font-italic">
        Du har inga receptdelningar att hantera
      </div>
      <div v-else class="mt-4">
        <div>Här listas recept som har delats med dig</div>
        <div class="mt-4">
          <hr />
          <div
            v-for="recipeShare in recipeShares"
            :key="recipeShare.key"
            :class="'row'"
          >
            <div class="col-12 col-sm-8">
              <div
                class="
                  h-100
                  d-flex
                  flex-column
                  justify-content-center
                  font-italic
                "
              >
                {{ getInformationalText(recipeShare) }}
              </div>
            </div>
            <div :class="['col-12', 'col-sm-4', { 'mt-3': !isWideView }]">
              <div class="row">
                <div class="col-6">
                  <button
                    class="btn btn-info btn-sm w-100"
                    @click="onInspectRecipeShare(recipeShare)"
                  >
                    Inspektera
                  </button>
                </div>
                <div class="col-6">
                  <button
                    class="btn btn-danger btn-sm w-100"
                    @click="onRemoveRecipeShare(recipeShare)"
                  >
                    Ta bort
                  </button>
                </div>
              </div>
            </div>
            <div class="col-12"><hr /></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RecipeService from "../../services/recipes.service.js";
import ImportSharedRecipesModal from "../modals/import-shared-recipes-modal.vue";
import moment from "moment";

export default {
  components: {
    ImportSharedRecipesModal,
  },
  data: function () {
    return {
      recipeShares: [],
      initializationError: false,
    };
  },
  computed: {
    user: function () {
      return this.$store.getters["auth/currentUser"];
    },
    noShares: function () {
      return !this.recipeShares || this.recipeShares.length < 1;
    },
    isWideView: function () {
      return this.$store.getters["isWideView"];
    },
  },
  methods: {
    onRecipeShareHandled: function () {
      this.getSharedRecipes();
    },
    onInspectRecipeShare: function (recipeShare) {
      this.$bus.dispatch("inspectSharedRecipesModal", { recipeShare });
    },
    onRemoveRecipeShare: function (recipeShare) {
      this.$bvModal
        .msgBoxConfirm(
          `Vill du verkligen inte ta del av dessa möjliga godheter?`,
          {
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "Nej tack",
            cancelTitle: "Avbryt",
          }
        )
        .then((result) => {
          if (result) {
            return RecipeService.deleteRecipeShare(recipeShare.id).then(() =>
              this.getSharedRecipes()
            );
          }
          return Promise.resolve();
        })
        .catch((e) => {
          console.log(e);
          this.$bus.dispatch("showToastFailure", {
            title: "Receptdelningen kunde inte tas bort",
            message: `Rackarns! Något gick fel när receptdelningen skulle tas bort. Uppdatera sidan och prova igen`,
          });
        });
    },
    createdAt: function (isoDate) {
      return moment(isoDate).format("YYYY-MM-DD");
    },
    getSenderName: function (recipeShare) {
      if (this.user.id === recipeShare.senderId) {
        return "Du själv";
      } else {
        return recipeShare.sender.name;
      }
    },
    getInformationalText: function (recipeShare) {
      var count = recipeShare?.recipes?.length;
      var firstRecipename = recipeShare?.recipes[0].name;
      if (count > 1) {
        return `${this.getSenderName(
          recipeShare
        )} har delat '${firstRecipename}' och ${
          count - 1
        } andra recpet med dig`;
      } else {
        return `${this.getSenderName(
          recipeShare
        )} har delat '${firstRecipename}' med dig`;
      }
    },
    getSharedRecipes: function () {
      RecipeService.getSharedRecipes(this.user.id)
        .then((recipeShares) => {
          this.recipeShares = recipeShares ?? [];
        })
        .catch((e) => {
          console.log(e);
          this.initializationError = true;
        });
    },
  },
  mounted() {
    this.getSharedRecipes();
  },
};
</script>

<style scoped>
</style>