<template>
  <div class="w-100" text-center>
    <add-recipe-modal
      :modalId="'addBankRecipe'"
      :recipeStatus="recipeStatus"
      @recipeAdded="onBankRecipeAdded"
    ></add-recipe-modal>
    <share-recipe-modal modalId="sharerecipe_overview"></share-recipe-modal>

    <b-sidebar
      id="sidebar-filters"
      title="Filter"
      class="text-left"
      shadow
      @change="onSidebarChange"
    >
      <div class="sidebar-filters-body">
        <div class="mt-4 mb-3 list-data-header">Sortering</div>
        <div class="d-flex justify-content-space-between align-items-center">
          <b-form-select
            v-model="sortProp"
            :options="sortOpts"
            size="sm"
          ></b-form-select>
          <font-awesome-icon
            icon="chevron-circle-up"
            class="text-info ml-4"
            size="lg"
            :class="[
              'clickable',
              'sort-order-icon',
              { 'sort-order-icon-rotate': !tmpFilters.sortAscending },
            ]"
            @click="tmpFilters.sortAscending = !tmpFilters.sortAscending"
          ></font-awesome-icon>
        </div>
        <div class="mt-5 mb-3 list-data-header">Omdöme</div>
        <dual-slider
          v-model="tmpFilters.rating"
          :disable="showTestRecipes"
        ></dual-slider>
        <div class="mt-5 mb-2">
          <b-form-checkbox
            name="check-button"
            switch
            size="lg"
            v-model="showTestRecipes"
          >
            Visa slussen
          </b-form-checkbox>
        </div>

        <button
          class="btn btn-info btn-sm mt-5"
          v-b-toggle.sidebar-filters
          @click="onApplyFilters"
        >
          Applicera
        </button>
      </div>
    </b-sidebar>

    <b-container>
      <b-row>
        <b-col cols="12">
          <div class="search-bar">
            <div>
              <form class="w-100">
                <div class="row">
                  <div class="col-12 col-sm-8 my-1">
                    <b-input-group size="sm">
                      <b-form-input
                        type="search"
                        placeholder="Ge mig något att jobba med..."
                        v-model="filters.text"
                      ></b-form-input>

                      <b-input-group-append
                        class="clickable"
                        v-b-toggle.sidebar-filters
                      >
                        <b-input-group-text class="bg-secondary">
                          <font-awesome-icon
                            icon="filter"
                            size="lg"
                          ></font-awesome-icon>
                        </b-input-group-text>
                      </b-input-group-append>
                    </b-input-group>
                  </div>
                  <div class="col-12 col-sm-4">
                    <button
                      type="submit"
                      class="btn btn-secondary btn-sm w-100 my-1"
                      @click.prevent="onSearch"
                    >
                      Sök
                    </button>
                  </div>
                </div>
              </form>
              <div :class="['row', isWideView ? 'mt-2' : 'mt-3']">
                <div class="col-12">
                  <button
                    class="btn btn-primary btn-sm w-100 my-1"
                    @click="onAddRecipeModalShow"
                  >
                    Nytt recept
                  </button>
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <div class="recipes-list loading-container">
      <div
        v-if="initialized && loading"
        class="loading-hud-fixed recipe-loader"
      >
        <div class="loader awesome-spin"></div>
      </div>
      <div v-else-if="loading" class="loading-hud recipe-loader mt-3">
        <div class="loader awesome-spin"></div>
      </div>

      <b-container :class="['hud-target', { blurred: loading }]">
        <b-row>
          <b-col
            v-for="recipe in recipes"
            :key="recipe.id"
            cols="12"
            sm="6"
            lg="4"
            class="mt-4"
          >
            <recipe-card
              :recipe="recipe"
              @removeRecipe="onRemoveRecipe"
              @addToMenu="onAddToMenu"
              @shareRecipe="onShareRecipe"
            ></recipe-card>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <div class="overflow-auto pagination-nav-container">
      <b-pagination
        v-model="filters.page"
        :total-rows="totalCount"
        :per-page="filters.pageSize"
        first-number
        last-number
        @change="onPaginate"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import RecipeCard from "../widgets/recipe-card.vue";
import {
  recipeBankItemStatus,
  recipeTestItemStatus,
} from "../../util/globals.js";
import AddRecipeModal from "../modals/add-recipe-modal.vue";
import ShareRecipeModal from "../modals/share-recipe-modal.vue";
import recipesService from "../../services/recipes.service.js";
import menuService from "../../services/menu.service.js";
import DualSlider from "../controls/dual-slider.vue";

const pageSize = 24;

export default {
  components: {
    RecipeCard,
    AddRecipeModal,
    ShareRecipeModal,
    DualSlider,
  },
  data: function () {
    return {
      loading: false,
      initialized: false,
      previousSearchText: "",
      error: "",
      filters: {
        sort: "name",
        rating: {
          min: 0,
          max: 5,
        },
        recipeStatus: recipeBankItemStatus,
        sortAscending: true,
      },
      tmpFilters: {
        sort: "name",
        rating: {
          min: 0,
          max: 5,
        },
        recipeStatus: recipeBankItemStatus,
        sortAscending: true,
      },
      sort: "name",
    };
  },
  computed: {
    showTestRecipes: {
      get() {
        return this.tmpFilters.recipeStatus === recipeTestItemStatus;
      },
      set(value) {
        if (value) {
          this.tmpFilters.recipeStatus = recipeTestItemStatus;
        } else {
          this.tmpFilters.recipeStatus = recipeBankItemStatus;
        }
      },
    },
    sortOpts: function () {
      return [
        { value: "name", text: "Namn" },
        { value: "created_at", text: "Skapad" },
        { value: "rating", text: "Omdöme", disabled: this.showTestRecipes },
      ];
    },
    sortProp: {
      get() {
        if (this.showTestRecipes && this.tmpFilters.sort == "rating") {
          return "name";
        }
        return this.tmpFilters.sort;
      },
      set(value) {
        this.tmpFilters.sort = value;
      },
    },
    sortOrderStyles: function () {
      return {};
    },
    isWideView: function () {
      return this.$store.getters["isWideView"];
    },
    recipes: function () {
      return this.$store.state.recipes.recipes;
    },
    totalCount: function () {
      return this.$store.state.recipes.metadata.total_records;
    },
    recipeStatus: function () {
      return recipeBankItemStatus;
    },
    successToastMessage: function () {
      return `'${this.recipeNameAddedToMenu}' tillagt i menyn! Hoppas det smakar!'`;
    },
  },
  methods: {
    onSidebarChange: function () {
      this.tmpFilters = {
        sort: this.filters.sort,
        rating: this.filters.rating,
        recipeStatus: this.filters.recipeStatus,
        sortAscending: this.filters.sortAscending,
      };
    },
    onApplyFilters: function () {
      if (this.tmpFilters.recipeStatus === recipeTestItemStatus) {
        this.filters = Object.assign(this.filters, {
          ...this.tmpFilters,
          rating: { min: 0, max: 5 },
        });
      } else {
        this.filters = Object.assign(this.filters, this.tmpFilters);
      }
      this.doSearch({ ...this.filters });
    },
    defaultFilters: function () {
      return {
        recipeBankID: this.$store.getters["recipebanks/selectedRecipeBankId"],
        page: 1,
        pageSize: pageSize,
        text: "",
        sort: "name",
        sortAscending: true,
        recipeStatus: recipeBankItemStatus,
        rating: {
          min: 0,
          max: 5,
        },
      };
    },
    onSearch: function () {
      this.filters = { ...this.filters, page: 1, text: this.filters.text };
      this.doSearch(this.filters);
    },
    onPaginate: function (index) {
      this.doSearch({ ...this.filters, page: index });
    },
    doSearch: function (filters) {
      this.loading = true;
      this.$store
        .dispatch("recipes/search", { searchFilter: filters })
        .catch(() => {
          this.error = "Fel uppstod när recept skulle hämtas";
        })
        .finally(() => {
          this.loading = false;
          this.initialized = true;
        });
    },
    onBankRecipeAdded: function () {
      this.doSearch({ ...this.filters });
    },
    onAddRecipeModalShow: function () {
      this.$bvModal.show("addBankRecipe");
    },
    onShareRecipe: function (data) {
      this.$bus.dispatch("sharerecipe_overview", data);
    },
    onRemoveRecipe: function (data) {
      this.$bvModal
        .msgBoxConfirm(
          `Är du säkert på att du vill ta bort '${data.recipe.name}'`,
          {
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "Ta bort",
            cancelTitle: "Avbryt",
          }
        )
        .then((result) => {
          if (result) {
            this.loading = true;
            return recipesService.delete({ id: data.recipe.id }).then(() => {
              return this.doSearch({ ...this.filters });
            });
          }
        })
        .catch((e) => {
          console.log(e);
          this.$bus.dispatch("showToastFailure", {
            title: "Recept kunde inte tas bort",
            message: `Ojdå! '${data.recipe.name}' kunde inte tas bort. Uppdatera sidan och försök igen eller kontakta supporten'`,
          });
        })
        .finally(() => (this.loading = false));
    },
    onAddToMenu: function (data) {
      menuService
        .addRecipe(
          this.$store.getters["recipebanks/selectedRecipeBankMenuId"],
          data.recipe.id
        )
        .then(() => {
          this.$bus.dispatch("showToastSuccess", {
            title: "Recept tillagt",
            message: `'${data.recipe.name}' tillagt i menyn! Hoppas det smakar!`,
          });
        })
        .catch(() => {
          this.$bus.dispatch("showToastFailure", {
            title: "Recept lades inte till",
            message: `Ojdå! '${data.recipe.name}' kunde inte läggas till i menyn. Försök igen eller kontakta supporten'`,
          });
        });
    },
    clearTempRecipeName: function () {
      this.recipeNameAddedToMenu = "";
    },
  },
  mounted() {
    this.filters = this.defaultFilters();
    this.error = "";
    this.doSearch({ ...this.filters });
  },
  watch: {
    "$store.state.recipebanks.selectedRecipeBank": {
      handler() {
        this.filters = this.defaultFilters();
        this.loading = true;
        this.error = "";
        this.doSearch({ ...this.filters });
      },
    },
  },
};
</script>

<style scoped>
.search-bar {
  width: 100%;
  padding: 1.5rem 0.75rem;
  background-color: #fcfcfc;
  border-radius: 0.15rem;
  box-shadow: 0.5px 1px 2px grey;
}

.recipe-loader {
  z-index: 10;
}

.sidebar-filters-body {
  padding: 0.5rem 1rem;
}

.sort-order-icon {
  transition: 0.6s ease;
}

.sort-order-icon-rotate {
  transform: rotate(180deg);
}
</style>