<template>
  <b-modal
    :id="modalId"
    title="Ändra receptbank"
    :visible="show"
    no-close-on-backdrop
    @hide="onClose"
    @ok="onSubmit"
    :cancel-title="$t('common.cancel')"
    ok-title="Spara"
  >
    <form>
      <input
        type="text"
        class="form-control"
        :placeholder="$t('common.name')"
        v-model="recipeBank.name"
        autocomplete="name"
      />
    </form>
  </b-modal>
</template>

<script>
import RecipeBankService from "../../services/recipebank.service.js";
export default {
  props: {
    modalId: {
      type: String,
      required: true,
    },
  },
  data: function () {
    return {
      show: false,
      recipeBank: {},
    };
  },
  methods: {
    emptyRecipeBank: function () {
      return { name: "" };
    },
    onClose: function () {
      this.recipeBank = this.emptyRecipeBank();
      this.show = false;
    },
    onSubmit: function () {
      RecipeBankService.update(this.recipeBank)
        .then(() => {
          this.$bus.dispatch("showToastSuccess", {
            title: "Uppdatering färdig",
            message: `Hej vad det går! Uppdateringen är klar.`,
          });
          this.$emit("recipeBankUpdated");
          this.$bvModal.hide(this.modalId);
        })
        .catch((err) => {
          console.log(err);
          this.$bus.dispatch("showToastFailure", {
            title: "Uppdatering misslyckades",
            message: `Hoppsan! Uppdateringen kunde inte göras. Kolla i loggarna för mer information.`,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.$bus.on(this.modalId, (data) => {
      this.recipeBank = { ...data.recipeBank };
      this.show = true;
    });
  },
  beforeDestroy() {
    this.$bus.remove(this.modalId);
  },
};
</script>

<style>
</style>