export default {
  app: {
    menuRoute: 'Meny',
    recipeRoute: 'Recept',
    admin: 'Admin'
  },
  common: {
    email: 'Email',
    password: 'Lösenord',
    save: 'Spara',
    cancel: 'Avbryt',
    name: 'Namn',
    source: 'Källa',
    notes: 'Anteckningar',
    tags: 'Tags'
  },
  login: {
    loginError: 'Felaktigt användarnamn/lösenord',
    forgotPassword: 'Glömt lösenord?',
    login: 'Logga in'
  },
  recipe: {
    addRecipe: 'Lägg till recept',
    updateRecipe: 'Uppdatera recept',
    invalidTag: 'Ogiltig namn på taggen',
    duplicateTag: 'Taggen finns redan',
    unableSaveRecipe: 'Kunde inte spara receptet',
    unableSaveImages: 'Kunde inte ladda upp bilder till receptet'
  },
  menu: {
    newRecipe: "Nytt recept",
    emptyMenuPrompt: "Din meny är tom, lägg till en rätt!",
    oops: "Ojdå!",
    errorOccurred: "Ett oväntat fel uppstod, kontakta supporten",
    unableToRead: "Kunde inte läsa menyn",
    menuTitle: "Veckomeny",
    linkToRecipe: "Gå till receptet"
  }
}