import AuthService from "../../services/auth.service.js";
import TokenService from "../../services/token.service.js";
import UserService from "../../services/user.service.js";

function getInitialState() {
  var session = JSON.parse(localStorage.getItem('minarecept_session'));
  var initialState = session
    ? { status: { loggedIn: true }, session }
    : { status: { loggedIn: false }, session: null };
  return initialState;
}



export const auth = {
  namespaced: true,
  state: { ...getInitialState() },
  getters: {
    isAdmin: state => state.session?.user ? state.session.user.role === 'admin' : false,
    currentUser: state => state.session?.user
  },
  actions: {
    setInitState({ commit }) {
      commit("setInitState", getInitialState())
    },
    login({ commit }, user) {
      return AuthService.login(user).then(
        user => {
          commit('loginSuccess', user);
          return Promise.resolve(user);
        },
        error => {
          commit('loginFailure');
          TokenService.removeSession();
          return Promise.reject(error);
        }
      );
    },
    logout({ commit, dispatch, state }) {
      AuthService.logout(state.user?.id);
      commit('logout');
      dispatch("resetState", {}, { root: true })
    },
    register({ commit }, user) {
      return AuthService.register(user).then(
        response => {
          commit('registerSuccess');
          return Promise.resolve(response.data);
        },
        error => {
          commit('registerFailure');
          return Promise.reject(error);
        }
      );
    },
    getSessionUser({ commit }) {
      return UserService.getSessionUser().then(
        user => {
          commit('setUser', user)
          Promise.resolve(user)
        },
        error => {
          commit('logout')
          Promise.reject(error)
        }
      )
    }
  },
  mutations: {
    setInitState(state, initialState) {
      Object.assign(state, initialState)
    },
    loginSuccess(state, session) {
      state.status.loggedIn = true;
      state.session = session;
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.session = null;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.session = null;
    },
    registerSuccess(state) {
      state.status.loggedIn = false;
    },
    registerFailure(state) {
      state.status.loggedIn = false;
    },
    setUser(state, user) {
      state.session = { ...state.session, user: user }
    }
  }
};