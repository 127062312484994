import api from './api.js'

class RecipeService {
  get(id) {
    return api.get(`/recipes/${id}`)
      .then(response => response.data?.recipe)
  }
  createRecipe(recipeBankID, recipe) {
    return api.post(`/recipebanks/${recipeBankID}/recipes`, recipe)
      .then(response => response.data?.recipe)
  }
  addRecipeImages(args) {
    return api.post(`/recipes/${args.recipeID}/images`, { imageUrls: args.urls })
  }
  updateRecipe(recipe) {
    return api.patch(`/recipes/${recipe.id}`, {
      name: recipe.name,
      source: recipe.source,
      notes: recipe.notes,
      tags: recipe.tags,
      status: recipe.status,
      rating: recipe.rating
    }).then(response => response.data?.recipe)
  }
  search(args) {
    var sort = args.sortAscending ? args.sort : `-${args.sort}`
    return api.get(`/recipebanks/${args.recipeBankID}/recipes?text=${args.text}&page=${args.page}&status=${args.recipeStatus}&page_size=${args.pageSize}&sort=${sort}&rating_min=${args.rating.min}&rating_max=${args.rating.max}`)
      .then(response => {
        return response.data
      });
  }
  delete(args) {
    return api.delete(`/recipes/${args.id}`)
  }
  syncRecipes(args) {
    return api.post(`/recipes/${args.recipeID}/images/sync`, { imageUrls: args.urls })
  }
  share(receiverEmail, recipeId) {
    return api.post(`/cooperation/recipes/share`, { receiverEmail: receiverEmail, recipeIds: [recipeId] })
  }
  getSharedRecipes(userId) {
    return api.get(`/users/${userId}/recipes/shares`, { userId })
      .then(response => response.data?.recipeShares)
  }
  deleteRecipeShare(recipeShareId) {
    return api.delete(`/cooperation/recipes/share/${recipeShareId}`)
  }
}

export default new RecipeService();