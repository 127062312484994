<template>
  <b-modal
    :id="modalId"
    :title="$t('recipe.addRecipe')"
    :visible="show"
    no-close-on-backdrop
    @hide="onClose"
    @ok="onSubmit"
    :cancel-title="$t('common.cancel')"
    :ok-title="$t('common.save')"
  >
    <input
      type="text"
      class="form-control"
      :placeholder="$t('common.name')"
      v-model="recipe.name"
    />
    <input
      type="text"
      class="form-control mt-3"
      :placeholder="$t('common.source')"
      v-model="recipe.source"
    />
    <textarea
      v-model="recipe.notes"
      cols="30"
      rows="4"
      class="form-control mt-3"
      :placeholder="$t('common.notes')"
    ></textarea>
    <b-form-tags
      input-id="recipe-tags"
      v-model="recipe.tags"
      class="mt-3"
      :placeholder="$t('common.tags')"
      :invalidTagText="$t('recipe.invalidTag')"
      :duplicateTagText="$t('recipe.duplicateTag')"
    >
    </b-form-tags>
    <upload-file class="mt-4" v-model="recipe.files"></upload-file>
    <div v-if="isBankRecipe" class="mt-4 d-flex">
      <b-form-checkbox id="checkbox-testrecipe" v-model="isTestRecipe">
        Spara som ett testrecept
      </b-form-checkbox>
      <div
        v-b-tooltip.hover
        title="Testrecept kan vara helt nya recept som du kanske inte vill lägga in direkt i din bank. Dessa sparas inte med vanliga recept utan hamnar i 'slussen' som kan filtreras fram på söksidan"
        class="ml-4"
      >
        <font-awesome-icon
          icon="info-circle"
          class="text-info"
          size="lg"
        ></font-awesome-icon>
      </div>
    </div>
    <b-form-rating
      v-if="!isTestRecipe"
      v-model="recipe.rating"
      size="lg"
      variant="variant"
      class="rating-viewer mt-4"
    ></b-form-rating>
  </b-modal>
</template>

<script>
import UploadFile from "../widgets/upload-file.vue";
import ImagesService from "../../services/images.service.js";
import RecipesService from "../../services/recipes.service.js";
import { recipeBankItemStatus, recipeTestItemStatus } from "../../util/globals";

export default {
  props: {
    modalId: {
      type: String,
      required: true,
    },
    recipeStatus: {
      type: Number,
      required: true,
    },
  },
  components: {
    UploadFile,
  },
  data: function () {
    return {
      show: false,
      loading: false,
      error: "",
      warning: "",
      recipe: {},
    };
  },
  computed: {
    isBankRecipe: function () {
      return this.recipeStatus == recipeBankItemStatus;
    },
    isTestRecipe: {
      get() {
        return this.recipe.status === recipeTestItemStatus;
      },
      set(val) {
        if (val) {
          this.recipe.status = recipeTestItemStatus;
        } else {
          this.recipe.status = this.recipeStatus;
        }
      },
    },
  },
  methods: {
    onClose: function () {
      this.recipe = this.emptyRecipe();
      this.show = false;
    },
    onSubmit: async function () {
      var filesToUpload = this.recipe.files;
      console.log("ADD RECIPE");
      console.log(this.recipe.files);
      this.loading = true;
      this.error = this.$t("recipe.unableSaveRecipe");
      this.$store
        .dispatch("recipes/create", {
          recipe: this.recipe,
        })
        .then((recipe) => {
          this.$emit("recipeAdded", { recipe });
          this.error = "";
          console.log("UPLOAD IMAGES TIME");
          console.log(filesToUpload);
          if (filesToUpload.length > 0) {
            this.warning = this.$t("recipe.unableSaveImages");
            return Promise.all([
              Promise.resolve(recipe),
              ImagesService.uploadImages(filesToUpload),
            ]);
          } else {
            return Promise.all([Promise.resolve(recipe)]);
          }
        })
        .then((results) => {
          console.log(results);
          if (results.length > 1) {
            let recipe = results[0];
            let imageUrls = results[1];
            return RecipesService.addRecipeImages({
              recipeID: recipe.id,
              urls: imageUrls,
            });
          }
          return Promise.resolve(results[0]);
        })
        .then(() => (this.warning = ""))
        .catch((e) => console.log(e))
        .finally(() => (this.loading = false));
    },
    emptyRecipe: function () {
      return {
        recipeBankID: this.$store.getters["recipebanks/selectedRecipeBankId"],
        name: "",
        source: "",
        notes: "",
        tags: [],
        files: [],
        status: this.recipeStatus,
        rating: 0,
      };
    },
  },
  mounted() {
    this.recipe = this.emptyRecipe();
  },
};
</script>

<style scoped>
.my-upload {
  border-color: #ced4da !important;
}

.my-upload > .icon {
  color: rgb(73, 80, 87) !important;
}
</style>