import api from "./api";
import TokenService from "./token.service";

class AuthService {
  login({ email, password }) {
    return api
      .post("/tokens/authenticate", {
        email,
        password
      })
      .then((response) => {
        TokenService.setSession(response.data);
        let session = response.data;
        return session?.user;
      });
  }

  logout(userID) {
    TokenService.removeSession();
    if (userID) {
      return api
        .post(`/tokens/revoke/${userID}`)
    } else {
      return Promise.resolve()
    }
  }
}

export default new AuthService();