<template>
  <div class="w-100 menu-page">
    <add-recipe-modal
      :modalId="'addMenuRecipe'"
      :recipeStatus="recipeStatus"
      @recipeAdded="onMenuEntryAdded"
    ></add-recipe-modal>
    <update-recipe-modal
      :modalId="'updateMenuRecipe'"
      @recipeUpdated="onRecipeUpdated"
    ></update-recipe-modal>
    <menuboard :showAddIcon="!error && !emptyMenu" @addClicked="onAddClicked">
      <div v-if="loading" class="w-100 d-flex justify-content-center">
        <div class="loader awesome-spin"></div>
      </div>
      <div v-else-if="error">
        <h3>{{ $t("menu.oops") }}</h3>
        <p>{{ $t("menu.errorOccurred") }}</p>
      </div>
      <div v-else-if="emptyMenu" class="text-center" style="margin: 10rem 0">
        <h3>{{ $t("menu.emptyMenuPrompt") }}</h3>
        <button class="btn btn-primary mt-2" @click="onAddRecipeModalShow">
          {{ $t("menu.newRecipe") }}
        </button>
      </div>
      <div v-else>
        <div
          v-for="(entry, index) in menuEntries"
          :key="entry.id"
          class="menu-entry w-100"
          @click="onEntryClicked(entry.id)"
        >
          <div class="w-100">
            <div class="menu-entry-header">{{ entry.name }}</div>
            <div v-if="isUrlSource(entry.source)">
              <a
                @click.stop="onSourceClicked(entry.source)"
                class="source-link"
              >
                <span class="menu-entry-source">
                  <font-awesome-icon
                    icon="external-link-alt"
                    size="xl"
                  ></font-awesome-icon>
                  {{ getDomainName(entry.source) }}
                </span>
              </a>
            </div>
            <div v-else>
              <div class="menu-entry-source">{{ entry.source }}</div>
            </div>
            <div class="mt-1 menu-icon-row">
              <font-awesome-icon
                v-if="entry.notes"
                :icon="['far', 'comment-dots']"
                class="mr-2"
              ></font-awesome-icon>
              <font-awesome-icon
                v-if="entry.images && entry.images.length > 0"
                :icon="['far', 'image']"
                class="mr-2"
              ></font-awesome-icon>
              <div v-if="entry.rating" class="rating-short mr-2">
                <span
                  ><font-awesome-icon
                    :icon="['fas', 'star']"
                  ></font-awesome-icon>
                  <span class="rating-short-text">{{
                    entry.rating
                  }}</span></span
                >
              </div>
            </div>
          </div>
          <div class="menu-entry-actions">
            <font-awesome-icon
              :icon="['fas', 'times']"
              size="lg"
              class="clickable"
              @click.stop="onRemoveClicked(entry, index)"
            ></font-awesome-icon>
          </div>
        </div>
      </div>
    </menuboard>
  </div>
</template>

<script>
import menuboard from "../widgets/menu-board.vue";
import AddRecipeModal from "../modals/add-recipe-modal.vue";
import UpdateRecipeModal from "../modals/update-recipe-modal.vue";
import SourceParserMixin from "../mixins/source-parser-mixin.js";
import {
  recipeBankItemStatus,
  recipeMenuItemStatus,
  recipeTestItemStatus,
} from "../../util/globals.js";
import recipesService from "../../services/recipes.service";

export default {
  components: {
    menuboard,
    AddRecipeModal,
    UpdateRecipeModal,
  },
  mixins: [SourceParserMixin],
  data: function () {
    return {
      error: "",
      loading: false,
    };
  },
  computed: {
    showLoader: function () {
      return true;
    },
    menuEntries: function () {
      return this.$store.state.menu.menu.entries;
    },
    emptyMenu: function () {
      return this.menuEntries < 1;
    },
    recipeStatus: function () {
      return recipeMenuItemStatus;
    },
    selectedRecipeBankSummary: function () {
      return this.$store.state.selectedRecipeBankSummary;
    },
  },
  methods: {
    onAddRecipeModalShow: function () {
      this.$bvModal.show("addMenuRecipe");
    },
    onMenuEntryAdded: function (data) {
      this.$store.dispatch("menu/addRecipe", data);
    },
    onAddClicked: function () {
      this.onAddRecipeModalShow();
    },
    onRemoveClicked: function (entry) {
      if (entry.status === recipeMenuItemStatus) {
        this.$bvModal
          .msgBoxConfirm("Vill du spara det här receptet?", {
            okTitle: "Ja",
            cancelTitle: "Nej",
            noCloseOnBackdrop: true,
            noCloseOnEsc: true,
          })
          .then((value) => {
            if (value) {
              return this.$bus.dispatch("showUpdateModal", { recipe: entry });
            } else {
              return recipesService
                .delete({ id: entry.id })
                .then(() => this.loadMenu());
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (entry.status === recipeTestItemStatus) {
        this.$bvModal
          .msgBoxConfirm("Vill du flytta receptet från slussen till banken?", {
            okTitle: "Ja",
            cancelTitle: "Nej",
            noCloseOnBackdrop: true,
            noCloseOnEsc: true,
          })
          .then((moveToBank) => {
            if (moveToBank) {
              this.$bus.dispatch("showUpdateModal", {
                recipe: { ...entry, status: recipeBankItemStatus },
              });
            } else {
              return this.$bvModal
                .msgBoxConfirm("Vill du ta bort receptet?", {
                  okTitle: "Ja",
                  cancelTitle: "Nej",
                  noCloseOnBackdrop: true,
                  noCloseOnEsc: true,
                })
                .then((removeRecipe) => {
                  if (removeRecipe) {
                    return recipesService
                      .delete({ id: entry.id })
                      .then(() => this.loadMenu());
                  } else {
                    return this.$store.dispatch("menu/removeRecipe", {
                      recipe: entry,
                    });
                  }
                })
                .catch((e) => {
                  console.log(e);
                  this.$bus.dispatch("showToastFailure", {
                    title: "Kunde inte ta bort receptet",
                    message: `Attans! Något gick fel när receptet skulle tas bort.`,
                  });
                });
            }
          });
      } else {
        this.$store.dispatch("menu/removeRecipe", { recipe: entry });
      }
    },
    onRecipeUpdated: function (data) {
      this.$store
        .dispatch("menu/removeRecipe", { recipe: data.recipe })
        .catch((e) => console.log(e));
    },
    onSourceClicked: function (url) {
      window.location.href = url;
    },
    loadMenu: function () {
      this.loading = true;
      this.$store
        .dispatch("menu/fetchMenu", {
          recipeBankID: this.$store.getters["recipebanks/selectedRecipeBankId"],
        })
        .catch((e) => {
          console.log(e);
          this.error = this.$t("menu.unableToRead");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onEntryClicked: function (id) {
      this.$router.push({ path: `/recipes/${id}` });
    },
  },
  mounted() {
    this.loadMenu();
  },
  watch: {
    "$store.state.recipebanks.selectedRecipeBank": {
      handler() {
        this.loadMenu();
      },
    },
  },
};
</script>

<style scoped>
.menu-entry {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem 0;
  text-align: left;
}
.menu-entry:not(:last-child) {
  border-bottom: 1px solid gray;
}

.menu-entry-header {
  font-size: 1.75rem;
  font-weight: bold;
}

.menu-entry-source {
  font-size: 1.25rem;
  font-style: italic;
}

.menu-entry-actions {
  display: flex;
  flex-direction: column;
  margin-top: 0.75rem;
}

.recipe-link {
  font-style: italic;
}

.url-source {
  margin-bottom: 0.4rem;
}

.menu-icon-row {
  display: flex;
  align-items: center;
  font-size: 1.25rem;
}

.menu-icon-row:first-child {
  margin-left: 0 !important;
}
</style>