import api from './api.js'

//Inspiration: https://www.bezkoder.com/vue-refresh-token/#Handle_expired_Token_in_Vue
class TokenService {
  getSession() {
    return JSON.parse(localStorage.getItem("minarecept_session"))
  }

  setSession(session) {
    localStorage.setItem("minarecept_session", JSON.stringify(session));
  }

  removeSession() {
    localStorage.removeItem("minarecept_session")
  }

  getLocalRefreshToken() {
    const session = JSON.parse(localStorage.getItem("minarecept_session"));
    return session?.authentication_token?.refreshToken?.token;
  }

  getLocalAccessToken() {
    const session = JSON.parse(localStorage.getItem("minarecept_session"));
    return session?.authentication_token?.authToken?.token;
  }

  getAuthenticatedUserRole() {
    const session = JSON.parse(localStorage.getItem("minarecept_session"));
    return session?.user?.role;
  }

  getUser() {
    const session = JSON.parse(localStorage.getItem("minarecept_session"));
    return session?.user
  }

  setUser(user) {
    let session = JSON.parse(localStorage.getItem("minarecept_session"));
    session.user = user
    localStorage.setItem("minarecept_session", JSON.stringify(session));
  }

  requestPasswordRest(userEmail) {
    return api.post("/tokens/password-reset", { email: userEmail })
  }
}

export default new TokenService();