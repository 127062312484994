<template>
  <b-modal
    :id="modalId"
    title="Begäran att lägga till användare"
    :visible="show"
    no-close-on-backdrop
    @hide="onClose"
    @ok="onSubmit"
    :cancel-title="$t('common.cancel')"
    ok-title="Skicka"
  >
    <input
      type="text"
      class="form-control"
      :placeholder="$t('common.name')"
      v-model="user.name"
    />
    <input
      type="text"
      class="form-control mt-3"
      placeholder="Email"
      v-model="user.email"
    />
  </b-modal>
</template>

<script>
import MessagesService from "../../services/messages.service.js";

export default {
  props: {
    modalId: {
      type: String,
      required: true,
    },
  },
  data: function () {
    return {
      show: false,
      loading: false,
      user: {},
    };
  },
  methods: {
    emptyUser: function () {
      return {
        name: "",
        email: "",
      };
    },
    onClose: function () {
      this.user = this.emptyUser();
      this.show = false;
    },
    onSubmit: function (modalOkEvt) {
      modalOkEvt.preventDefault();
      this.loading = true;
      MessagesService.sendAddUserRequest({ ...this.user })
        .then(() => {
          this.$bus.dispatch("showToastSuccess", {
            title: "Begäran sänd",
            message: `Fort var det gjort! Vi kommer att behandla din begäran att lägga till ${this.user.name} inom kort.`,
          });
          this.$bvModal.hide(this.modalId);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (this.loading = false));
    },
  },
  mounted() {
    this.user = this.emptyUser();
  },
};
</script>

<style>
</style>