<template>
  <b-modal
    :id="modalId"
    title="Skapa receptbank"
    :visible="show"
    no-close-on-backdrop
    @hide="onClose"
    @ok="onSubmit"
    :cancel-title="$t('common.cancel')"
    ok-title="Skapa"
  >
    <input
      type="text"
      ref="recipeBankName"
      class="form-control mt-3"
      placeholder="Namn"
      v-model="recipeBank.name"
    />
  </b-modal>
</template>

<script>
export default {
  props: {
    modalId: {
      type: String,
      required: true,
    },
  },
  data: function () {
    return {
      show: false,
      loading: false,
      recipeBank: {},
    };
  },
  methods: {
    emptyRecipeBank: function () {
      return {
        name: "",
      };
    },
    onClose: function () {
      this.recipeBank = this.emptyRecipeBank();
      this.show = false;
    },
    onSubmit: function () {
      this.loading = true;
      this.$store
        .dispatch("recipebanks/create", { ...this.recipeBank })
        .then(() => this.$store.dispatch("recipebanks/fetchRecipeBank"))
        .catch((e) => {
          console.log(e);
          return this.$bus.dispatch("showToastFailure", {
            title: "Kunde inte skapa receptbank",
            message: `Ojdå! '${this.recipeBank.name}' kunde inte skapas. Uppdatera sidan och försök igen eller kontakta supporten'`,
          });
        })
        .finally(() => (this.loading = false));
    },
  },
  mounted() {
    this.recipeBank = this.emptyRecipeBank();
  },
};
</script>

<style>
</style>