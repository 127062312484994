export default {
  app: {
    menuRoute: 'Menu',
    recipeRoute: 'Recipes',
    admin: 'Admin'
  },
  common: {
    email: 'Email',
    password: 'Password',
    save: 'Save',
    cancel: 'Cancel',
    name: 'Name',
    source: 'Source',
    notes: 'Notes',
    tags: 'Tags'
  },
  login: {
    loginError: 'Invalid username/password',
    forgotPassword: 'Forgot password?',
    login: 'Sign in'
  },
  recipe: {
    addRecipe: 'Add recipe',
    updateRecipe: 'Update recipe',
    invalidTag: 'Invalid tag name',
    duplicateTag: 'Tag already exist',
    unableSaveRecipe: 'Failed to save recipe',
    unableSaveImages: 'Failed to save images to recipe'
  },
  menu: {
    newRecipe: "New recipe",
    emptyMenuPrompt: "Your menu is empty, add a course!",
    oops: "Oops!",
    errorOccurred: "An unexpected error occurred, contact support",
    unableToRead: "Could not read menu",
    menuTitle: "Menu of the week",
    linkToRecipe: "Navigate to recipe"
  }
}