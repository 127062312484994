<template>
  <div id="app">
    <success-toast
      :title="successToast.title"
      :message="successToast.message"
      @hidden="clearSuccessToast"
    ></success-toast>
    <failure-toast
      :title="failureToast.title"
      :message="failureToast.message"
      @hidden="clearFailureToast"
    ></failure-toast>
    <navbar v-if="showAppBar">navbar</navbar>
    <div class="body-container">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import navbar from "./navbar.vue";
import SuccessToast from "./components/toasts/success-toast.vue";
import FailureToast from "./components/toasts/failure-toast.vue";

export default {
  name: "App",
  components: {
    navbar,
    SuccessToast,
    FailureToast,
  },
  data: function () {
    return {
      successToast: { title: "", message: "" },
      failureToast: { title: "", message: "" },
    };
  },
  computed: {
    showAppBar: function () {
      return !this.$route.meta.hideNavbar;
    },
    route: function () {
      return this.$route;
    },
  },
  methods: {
    logOut: function () {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login").catch(() => {});
    },
    onResize: function () {
      this.$store.commit("setViewPort", {
        width: window.innerWidth,
        height: window.innerHeight,
      });
    },
    clearSuccessToast: function () {
      this.successToast = { title: "", message: "" };
    },
    clearFailureToast: function () {
      this.failureToast = { title: "", message: "" };
    },
  },
  mounted() {
    this.$bus.on("logout", () => {
      this.logOut();
    });

    this.$bus.on("showToastSuccess", (data) => {
      this.successToast = data;
      this.$bvToast.show("success-toast");
    });

    this.$bus.on("showToastFailure", (data) => {
      this.failureToast = data;
      this.$bvToast.show("failure-toast");
    });

    this.onResize();
    window.addEventListener("resize", this.onResize);
  },
  beforeDestroy() {
    this.$bus.remove("logout");
    this.$bus.remove("showToastSuccess");
    this.$bus.remove("showFailureToast");
    window.removeEventListener("resize", this.onResize);
  },
};
</script>

<style>
@import "./styles/styles.css";
@import "./styles/spinners.css";
</style>
