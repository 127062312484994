import api from './api.js'

const regExp = new RegExp('^(.+)(/v1)');
class ImagesService {
  uploadImages(files) {
    var formData = new FormData()
    files.forEach(file => {
      formData.append(`recipe_images`, file.file)
    })
    return api.post(`/images/recipes`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then(response => response.data?.images)
  }
  get(source) {
    var resource = source.replace(regExp, "")
    return api.get(resource, { responseType: 'blob' }).then(response => response.data);
  }
}

export default new ImagesService();