<template>
  <div class="w-100 admin-page">
    <b-container>
      <b-row>
        <b-col cols="12">
          <div class="admin-search-bar">
            <div>
              <form class="w-100">
                <div class="row">
                  <div class="col-12 col-sm-10 my-1">
                    <input
                      type="search"
                      class="form-control form-control-sm"
                      placeholder="Ge mig något att jobba med..."
                      v-model="filters.text"
                    />
                  </div>
                  <div class="col-12 col-sm-2">
                    <button
                      type="submit"
                      class="btn btn-secondary btn-sm w-100 my-1"
                      @click.prevent="onSearch"
                    >
                      Sök
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <div
      v-if="isWideView"
      :class="[
        isWideView ? 'text-center' : 'text-left',
        'row',
        'user-row',
        'profile-data-header',
      ]"
    >
      <div :class="['col-5', { 'text-left': isWideView }]">Namn</div>
      <div class="col-3">Medlemmar</div>
      <div class="col-3">Recept</div>
      <div class="col-1"></div>
      <div class="col-12 p-0 list-header-divider"></div>
    </div>

    <div
      v-for="recipeBank in recipeBanks"
      :key="recipeBank.id"
      :class="[isWideView ? 'text-center' : 'text-left', 'row', 'user-row']"
    >
      <div
        :class="[
          'col-12',
          'col-sm-5',
          'admin-list-cell',
          { 'text-left': isWideView },
          { 'font-weight-bold': !isWideView },
        ]"
      >
        {{ recipeBank.name }}
      </div>
      <div
        class="col-12 col-sm-3 admin-list-cell"
        :id="'adminRecipeBankMembers_' + recipeBank.id"
      >
        <div v-if="isWideView">{{ recipeBank.users.length }}</div>
        <div v-else>{{ recipeBank.users.length }} medlemmar</div>
        <b-tooltip
          :target="'adminRecipeBankMembers_' + recipeBank.id"
          triggers="hover"
        >
          <div
            v-for="user in recipeBank.users"
            :key="recipeBank.id + '_' + user.id"
          >
            {{ user.name }} ({{ user.email }})
          </div>
        </b-tooltip>
      </div>

      <div v-if="isWideView" class="col-12 col-sm-3 admin-list-cell">
        {{ recipeBank.recipeCount }}
      </div>
      <div v-else class="col-12 col-sm-3 admin-list-cell">
        {{ recipeBank.recipeCount }} recept
      </div>
      <div
        :class="[
          'col-12',
          'col-sm-1',
          'admin-list-cell',
          { adjusted: isWideView },
        ]"
      >
        <b-dropdown
          size="sm"
          variant="link"
          toggle-class="text-decoration-none"
          droprigth
          :no-caret="isWideView"
          :text="'Hantera'"
          :class="['my-secret-class']"
        >
          <template v-if="isWideView" #button-content>
            <font-awesome-icon icon="ellipsis-v"></font-awesome-icon>
          </template>
          <b-dropdown-item @click="onEditRecipeBank(recipeBank)"
            >Redigera</b-dropdown-item
          >
          <b-dropdown-item
            v-if="recipeBank.recipeCount > 0"
            @click="onExportRecipeBank(recipeBank)"
            >Exportera</b-dropdown-item
          >
          <b-dropdown-item @click="onDeleteRecipeBank(recipeBank)"
            >Ta bort</b-dropdown-item
          >
        </b-dropdown>
      </div>
      <div class="col-12 p-0"><hr /></div>
    </div>

    <div class="overflow-auto pagination-nav-container">
      <b-pagination
        v-model="filters.page"
        :total-rows="totalCount"
        :per-page="filters.pageSize"
        first-number
        last-number
        @change="onPaginate"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import RecipeBankService from "../../services/recipebank.service.js";

const defaultPageSize = 30;
const sort = "name";

export default {
  data: function () {
    return {
      filters: {},
    };
  },
  computed: {
    totalCount: function () {
      return this.$store.getters["admin/totalRecipeBanks"];
    },
    isWideView: function () {
      return this.$store.getters["isWideView"];
    },
    recipeBanks: function () {
      return this.$store.state.admin.recipeBanks?.list;
    },
  },
  methods: {
    onExportRecipeBank: function (recipeBank) {
      RecipeBankService.exportRecipesFromBank(recipeBank.id).then((recipes) => {
        var dataStr =
          "data:text/json;charset=utf-8," + encodeURIComponent(recipes);

        var download = document.createElement("a");
        download.setAttribute("href", dataStr);
        download.setAttribute("download", `${recipeBank.name}_recipes.json`);
        download.click();
        download.remove();
      });
    },
    onEditRecipeBank: function (recipeBank) {
      this.$router.push({ path: `/recipebanks/${recipeBank.id}` });
    },
    onDeleteRecipeBank: function (recipeBank) {
      this.$bvModal
        .msgBoxConfirm(
          `Är du säkert på att du vill ta bort receptbanken '${recipeBank.name}'?`,
          {
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "Ta bort",
            cancelTitle: "Avbryt",
          }
        )
        .then((result) => {
          if (result) {
            return RecipeBankService.removeRecipeBank(recipeBank.id)
              .then(() => this.$store.dispatch("recipebanks/fetchRecipeBank"))
              .then(() => {
                this.search(this.filters);
              });
          }
          return Promise.resolve();
        })
        .catch((e) => {
          console.log(e);
          this.$bus.dispatch("showToastFailure", {
            title: "Receptbanken kunde inte tas bort",
            message: `Oops! Något gick fel när receptbanken '${recipeBank.name}' skulle tas bort. Kolla i loggarna för mer information`,
          });
        });
    },
    onSearch: function () {
      this.filters = { ...this.defaultFilters(), text: this.filters.text };
      this.search(this.filters);
    },
    defaultFilters: function () {
      return {
        page: 1,
        pageSize: defaultPageSize,
        text: "",
        sort: sort,
      };
    },
    onPaginate: function (index) {
      this.search({ ...this.filters, page: index });
    },
    search: function (filters) {
      this.$store.dispatch("admin/searchRecipeBanks", { filters: filters });
    },
    onRecipeBankUpdated: function () {
      this.search(this.filters);
      this.$store.dispatch("recipebanks/fetchRecipeBank");
    },
  },
  mounted() {
    this.filters = this.defaultFilters();
    this.search(this.filters);
  },
};
</script>

<style scoped>
.dropdown >>> button {
  text-align: left;
  border: none;
  padding: 0.5rem 0 0 0;
}

.dropdown >>> button:focus,
.dropdown >>> button:focus-visible {
  outline: none;
  box-shadow: none;
}

.adjusted {
  margin-top: -0.5rem;
}
</style>