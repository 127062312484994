import UserService from '../../services/user.service.js'

const initialState = () => {
  return {
    users: [],
    metadata: {}
  }
}

export const users = {
  namespaced: true,
  state: initialState(),
  actions: {
    setInitState({ commit }) {
      commit("setInitState", initialState())
    },
    search({ commit }, data) {
      UserService.searchUsers(data.filters)
        .then(
          result => {
            commit('setMetadata', result.metadata)
            commit('setUsers', result.users?.length ? result.users : [])
            return Promise.resolve(result)
          },
          error => Promise.reject(error)
        )
    }
  },
  mutations: {
    setInitState(state, initialState) {
      Object.assign(state, initialState)
    },
    setUsers(state, users) {
      state.users = [...users]
    },
    setMetadata(state, metadata) {
      state.metadata = metadata
    }
  }
};