var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-100 admin-page"},[_c('admin-edit-user-modal',{attrs:{"modalId":'adminEditUser'},on:{"userUpdated":_vm.onUserUpdated}}),_c('add-user-modal',{attrs:{"modalId":'adminAddUser'},on:{"userAdded":_vm.onUserAdded}}),_c('b-container',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"admin-search-bar"},[_c('div',[_c('form',{staticClass:"w-100"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-sm-10 my-1"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.filters.text),expression:"filters.text"}],staticClass:"form-control form-control-sm",attrs:{"type":"search","placeholder":"Ge mig något att jobba med..."},domProps:{"value":(_vm.filters.text)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.filters, "text", $event.target.value)}}})]),_c('div',{staticClass:"col-12 col-sm-2"},[_c('button',{staticClass:"btn btn-secondary btn-sm w-100 my-1",attrs:{"type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.onSearch.apply(null, arguments)}}},[_vm._v(" Sök ")])])])]),_c('div',{staticClass:"row mt-2"},[_c('div',{staticClass:"col-12"},[_c('button',{staticClass:"btn btn-primary btn-sm w-100 my-1",on:{"click":_vm.onAddUser}},[_vm._v(" Ny anändare ")])])])])])])],1)],1),(_vm.isWideView)?_c('div',{class:[
      _vm.isWideView ? 'text-center' : 'text-left',
      'row',
      'user-row',
      'profile-data-header' ]},[_c('div',{class:['col-3', { 'text-left': _vm.isWideView }]},[_vm._v("Namn")]),_c('div',{class:['col-3', { 'text-left': _vm.isWideView }]},[_vm._v("Email")]),_c('div',{staticClass:"col-2"},[_vm._v("Status")]),_c('div',{staticClass:"col-3"},[_vm._v("Receptbanker")]),_c('div',{staticClass:"col-1"}),_c('div',{staticClass:"col-12 p-0 list-header-divider"})]):_vm._e(),_vm._l((_vm.users),function(user){return _c('div',{key:user.email,class:[_vm.isWideView ? 'text-center' : 'text-left', 'row', 'user-row']},[_c('div',{class:[
        'col-12',
        'col-sm-3',
        'admin-list-cell',
        { 'text-left': _vm.isWideView },
        { 'font-weight-bold': !_vm.isWideView } ]},[_vm._v(" "+_vm._s(user.name)+" ")]),_c('div',{class:[
        'col-12',
        'col-sm-3',
        'admin-list-cell',
        { 'text-left': _vm.isWideView } ]},[_vm._v(" "+_vm._s(user.email)+" ")]),_c('div',{class:[
        'col-12',
        'col-sm-2',
        'admin-list-cell',
        { 'status-limited-width': !_vm.isWideView } ]},[(user.activated)?_c('b-badge',{attrs:{"variant":"success"}},[_vm._v("Aktiverad")]):_c('b-badge',{attrs:{"variant":"warning"}},[_vm._v("Ej aktiverad")])],1),(_vm.isWideView)?_c('div',{staticClass:"col-12 col-sm-3 admin-list-cell"},[_vm._v(" "+_vm._s(user.recipeBankCount)+" ")]):_c('div',{staticClass:"col-12 col-sm-3 admin-list-cell"},[_vm._v(" "+_vm._s(user.recipeBankCount)+" receptbanker ")]),_c('div',{class:[
        'col-12',
        'col-sm-1',
        'admin-list-cell',
        { adjusted: _vm.isWideView } ]},[_c('b-dropdown',{attrs:{"size":"sm","variant":"link","toggle-class":"text-decoration-none","droprigth":"","no-caret":_vm.isWideView,"text":'Hantera'},scopedSlots:_vm._u([(_vm.isWideView)?{key:"button-content",fn:function(){return [_c('font-awesome-icon',{attrs:{"icon":"ellipsis-v"}})]},proxy:true}:null],null,true)},[_c('b-dropdown-item',{on:{"click":function($event){return _vm.onUserEdit(user)}}},[_vm._v("Redigera")]),(!user.activated)?_c('b-dropdown-item',{on:{"click":function($event){return _vm.onSendActivationLink(user)}}},[_vm._v("Skicka aktiveringslänk")]):_c('b-dropdown-item',{on:{"click":function($event){return _vm.onSendPasswordReset(user)}}},[_vm._v("Återställ lösenord")]),_c('b-dropdown-item',{staticClass:"text-danger",on:{"click":function($event){return _vm.onDeleteUser(user)}}},[_vm._v("Ta bort")])],1)],1),_vm._m(0,true)])}),_c('div',{staticClass:"overflow-auto pagination-nav-container"},[_c('b-pagination',{attrs:{"total-rows":_vm.totalCount,"per-page":_vm.filters.pageSize,"first-number":"","last-number":""},on:{"change":_vm.onPaginate},model:{value:(_vm.filters.page),callback:function ($$v) {_vm.$set(_vm.filters, "page", $$v)},expression:"filters.page"}})],1)],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-12 p-0"},[_c('hr')])}]

export { render, staticRenderFns }