<template>
  <div class="w-100">
    <b-form-file
      id="file-upload-input"
      ref="fileUploadInput"
      accept="image/jpeg, image/png, image/jpg"
      no-drop
      plain
      hidden
      multiple
      @input="onFileAdded"
    ></b-form-file>
    <div class="image-preview">
      <div
        v-for="(file, index) in value"
        :key="file.ref"
        class="mr-3 mt-3 recipe-image-container"
      >
        <img :ref="file.ref" alt="fel" class="recipe-image" />
        <div class="delete-overlay clickable">
          <div class="delete-icon" @click.stop="onDeleteImage(index)">
            <font-awesome-icon
              icon="times"
              class="clickable"
            ></font-awesome-icon>
          </div>
        </div>
      </div>
    </div>
    <button class="btn btn-secondary mt-3" @click="onBrowseFiles">
      <font-awesome-icon
        icon="cloud-upload-alt"
        class="mr-2"
      ></font-awesome-icon>
      Ladda upp bild
    </button>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";

export default {
  props: {
    value: Array,
  },
  methods: {
    onBrowseFiles: function () {
      this.$refs.fileUploadInput.$el.click();
    },
    onFileAdded: function (e) {
      let payload = [...this.value];
      e.forEach((f) => {
        payload.push({
          file: f,
          ref: uuidv4(),
        });
      });

      this.$emit("input", payload);
      this.$refs.fileUploadInput.$el.value = null;
    },
    onDeleteImage: function (index) {
      let payload = [...this.value];
      payload.splice(index, 1);
      console.log(payload);
      this.$emit("input", payload);
    },
  },
  watch: {
    value: function (newVal) {
      newVal.forEach((f) => {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.$refs[f.ref][0].src = e.target.result;
        };
        reader.readAsDataURL(f.file);
      });
    },
  },
};
</script>

<style scoped>
.drop-container {
  border: 2px dashed #ced4da;
}

.drop-container:hover {
  border-width: 3px;
}

.drop-area {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.image-preview {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.recipe-image-container {
  width: 100px;
  display: grid;
}

.recipe-image {
  width: 100px;
  grid-column: 1;
  grid-row: 1;
}

.delete-overlay {
  display: flex;
  justify-content: flex-end;
  align-items: top;
  grid-column: 1;
  grid-row: 1;
  width: 100%;
  height: 100%;
}

.delete-icon {
  padding: 5px;
  border-radius: 50px;
  background-color: #fff;
  height: 31px;
  width: 31px;
  text-align: center;
  box-shadow: 0 1px 2px grey;
}

.delete-icon:hover {
  box-shadow: 0 1.5px 4px grey;
}
</style>