import api from './api.js'

class MenuService {
  getMenuForRecipeBank(recipeBankID) {
    return api.get(`/recipebanks/${recipeBankID}/menu`)
      .then((response) => {
        return response.data?.menu;
      })
  }
  createMenu(recipeBankID) {
    return api.post(`/recipebanks/${recipeBankID}/menu`)
      .then((response) => {
        return response.data?.menu;
      })
  }
  addRecipe(menuID, recipeID) {
    return api.post(`/menu/${menuID}/entry/${recipeID}`)
  }
  removeRecipe(menuID, recipeID) {
    return api.delete(`/menu/${menuID}/entry/${recipeID}`)
  }
}

export default new MenuService();