var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-100 admin-page"},[_c('b-container',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"admin-search-bar"},[_c('div',[_c('form',{staticClass:"w-100"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-sm-10 my-1"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.filters.text),expression:"filters.text"}],staticClass:"form-control form-control-sm",attrs:{"type":"search","placeholder":"Ge mig något att jobba med..."},domProps:{"value":(_vm.filters.text)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.filters, "text", $event.target.value)}}})]),_c('div',{staticClass:"col-12 col-sm-2"},[_c('button',{staticClass:"btn btn-secondary btn-sm w-100 my-1",attrs:{"type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.onSearch.apply(null, arguments)}}},[_vm._v(" Sök ")])])])])])])])],1)],1),(_vm.isWideView)?_c('div',{class:[
      _vm.isWideView ? 'text-center' : 'text-left',
      'row',
      'user-row',
      'profile-data-header' ]},[_c('div',{class:['col-5', { 'text-left': _vm.isWideView }]},[_vm._v("Namn")]),_c('div',{staticClass:"col-3"},[_vm._v("Medlemmar")]),_c('div',{staticClass:"col-3"},[_vm._v("Recept")]),_c('div',{staticClass:"col-1"}),_c('div',{staticClass:"col-12 p-0 list-header-divider"})]):_vm._e(),_vm._l((_vm.recipeBanks),function(recipeBank){return _c('div',{key:recipeBank.id,class:[_vm.isWideView ? 'text-center' : 'text-left', 'row', 'user-row']},[_c('div',{class:[
        'col-12',
        'col-sm-5',
        'admin-list-cell',
        { 'text-left': _vm.isWideView },
        { 'font-weight-bold': !_vm.isWideView } ]},[_vm._v(" "+_vm._s(recipeBank.name)+" ")]),_c('div',{staticClass:"col-12 col-sm-3 admin-list-cell",attrs:{"id":'adminRecipeBankMembers_' + recipeBank.id}},[(_vm.isWideView)?_c('div',[_vm._v(_vm._s(recipeBank.users.length))]):_c('div',[_vm._v(_vm._s(recipeBank.users.length)+" medlemmar")]),_c('b-tooltip',{attrs:{"target":'adminRecipeBankMembers_' + recipeBank.id,"triggers":"hover"}},_vm._l((recipeBank.users),function(user){return _c('div',{key:recipeBank.id + '_' + user.id},[_vm._v(" "+_vm._s(user.name)+" ("+_vm._s(user.email)+") ")])}),0)],1),(_vm.isWideView)?_c('div',{staticClass:"col-12 col-sm-3 admin-list-cell"},[_vm._v(" "+_vm._s(recipeBank.recipeCount)+" ")]):_c('div',{staticClass:"col-12 col-sm-3 admin-list-cell"},[_vm._v(" "+_vm._s(recipeBank.recipeCount)+" recept ")]),_c('div',{class:[
        'col-12',
        'col-sm-1',
        'admin-list-cell',
        { adjusted: _vm.isWideView } ]},[_c('b-dropdown',{class:['my-secret-class'],attrs:{"size":"sm","variant":"link","toggle-class":"text-decoration-none","droprigth":"","no-caret":_vm.isWideView,"text":'Hantera'},scopedSlots:_vm._u([(_vm.isWideView)?{key:"button-content",fn:function(){return [_c('font-awesome-icon',{attrs:{"icon":"ellipsis-v"}})]},proxy:true}:null],null,true)},[_c('b-dropdown-item',{on:{"click":function($event){return _vm.onEditRecipeBank(recipeBank)}}},[_vm._v("Redigera")]),(recipeBank.recipeCount > 0)?_c('b-dropdown-item',{on:{"click":function($event){return _vm.onExportRecipeBank(recipeBank)}}},[_vm._v("Exportera")]):_vm._e(),_c('b-dropdown-item',{on:{"click":function($event){return _vm.onDeleteRecipeBank(recipeBank)}}},[_vm._v("Ta bort")])],1)],1),_vm._m(0,true)])}),_c('div',{staticClass:"overflow-auto pagination-nav-container"},[_c('b-pagination',{attrs:{"total-rows":_vm.totalCount,"per-page":_vm.filters.pageSize,"first-number":"","last-number":""},on:{"change":_vm.onPaginate},model:{value:(_vm.filters.page),callback:function ($$v) {_vm.$set(_vm.filters, "page", $$v)},expression:"filters.page"}})],1)],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-12 p-0"},[_c('hr')])}]

export { render, staticRenderFns }