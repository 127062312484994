import Vue from 'vue'
import VueI18n from 'vue-i18n'
import messages from './translations.js'

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: 'sv',
  messages,
})

export default i18n;