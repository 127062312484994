<template>
  <div class="recipebank-card app-card loading-container">
    <div :class="['hud-target', { blurred: loading }]">
      <h2 class="text-left">Skapa ny receptbank</h2>
      <p class="text-left">
        Du har inte skapat någon receptbank ännu. Skapa en och börja samla dina
        favoritrecept, planera menyer och dela med dig av dina absoluta godbitar
        till andra du känner!
      </p>
      <div class="text-left mt-5">
        <label for="bank-name" class="text-left"
          >Ge din receptbank ett namn</label
        >
        <input
          type="text"
          id="bank-name"
          class="form-control"
          placeholder="Namn"
          v-model="recipebank.name"
        />
      </div>
      <br />
      <div class="text-left">
        <label for="user-email" class="text-left"
          >Det blir roligare med fler. Lägg till en kompis</label
        >
        <form class="w-100">
          <div class="form-inline">
            <input
              type="text"
              id="user-email"
              class="form-control w-65"
              style="width: 65%"
              placeholder="Email"
              v-model="email"
            />
            <button
              class="btn btn-secondary"
              style="width: 30%"
              @click.prevent="onAddEmail"
              type="submit"
            >
              Lägg till
            </button>
          </div>
        </form>
        <div
          v-for="(email, index) in recipebank.collaborators"
          :key="index"
          class="mt-2"
        >
          <b-badge variant="dark"
            >{{ email
            }}<font-awesome-icon
              icon="times"
              size="lg"
              class="ml-4"
              @click="onDeleteEmail(index)"
            ></font-awesome-icon
          ></b-badge>
        </div>

        <div class="mt-5">
          <button class="btn btn-primary w-100" @click="onSaveRecipeBank">
            Spara
          </button>
          <div v-if="error" class="invalid-feedback d-block text-left">
            {{ error }}
          </div>
        </div>
      </div>
    </div>
    <div v-if="loading" class="loading-hud">
      <div class="loader awesome-spin"></div>
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      loading: false,
      error: "",
      recipebank: {
        name: "",
        collaborators: [],
      },
      email: "",
    };
  },
  methods: {
    onAddEmail: function () {
      if (this.email) {
        this.recipebank.collaborators.push(this.email);
        this.email = "";
      }
    },
    onDeleteEmail: function (index) {
      this.recipebank.collaborators.splice(index, 1);
    },
    onSaveRecipeBank: function () {
      this.false = true;
      this.$store
        .dispatch("recipebanks/create", this.recipebank)
        .catch(() => {
          this.error = "Misslyckades att skapa receptbank";
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.recipebank-card {
  padding: 4rem 2rem;
  background-color: #fcfcfc;
  max-width: 600px;
}
</style>