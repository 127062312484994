<template>
  <b-container>
    <b-card title="Card Title" no-body class="admin-card">
      <b-card-header header-tag="nav" class="admin-nav">
        <b-nav card-header>
          <b-nav-item
            to="/admin/users"
            :class="{ 'nav-selected': isActiveTab('admin-users') }"
            >Användare
          </b-nav-item>
          <b-nav-item
            to="/admin/recipebanks"
            :class="{ 'nav-selected': isActiveTab('admin-recipebanks') }"
            >Receptbanker
          </b-nav-item>
          <b-nav-item
            to="/admin/messages"
            :class="{ 'nav-selected': isActiveTab('admin-message') }"
            >Meddelanden
          </b-nav-item>
        </b-nav>
      </b-card-header>

      <b-card-body class="text-center">
        <router-view></router-view>
      </b-card-body>
    </b-card>
  </b-container>
</template>

<script>
export default {
  computed: {
    currentRoute: function () {
      return this.$route;
    },
  },
  methods: {
    isActiveTab: function (tab) {
      return this.currentRoute.name.includes(tab);
    },
  },
};
</script>

<style scoped>
.admin-card {
  width: 100%;
  background-color: #fcfcfc;
  border-radius: 0.15rem;
  box-shadow: 0.5px 1px 2px grey;
  text-align: left;
}

.admin-nav {
  background-color: #fcfcfc !important;
}

.nav-selected {
  font-weight: bold;
  border-bottom: 2px solid #343a40;
}
</style>