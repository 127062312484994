<template>
  <div>
    <add-user-modal :modalId="'adminAddUser_messages'"></add-user-modal>
    <div v-if="fetchMessagesError">
      <div><font-awesome-icon icon="bug" size="5x"></font-awesome-icon></div>
      <p class="mt-4">
        Fel uppstod när meddelandena skulle hämtas. Kolla i loggarna för mer
        info
      </p>
    </div>
    <div v-else-if="noMessages" class="font-italic">Din inkorg är tom</div>
    <div v-else class="message-inbox">
      <div
        v-if="showMessagesOverview"
        :class="['text-left', 'message-list', { 'w-100': !isMediumPlusView }]"
      >
        <div
          v-for="(message, index) in addUserMessages"
          :key="message.id"
          :class="['message-overview', { 'font-weight-bold': !message.isRead }]"
          @click="onSelectMessage(message)"
        >
          <hr v-if="index > 0" />
          <div
            :class="[
              'p-2',
              'message-entry',
              { 'selected-message': isSelectedMessage(message) },
            ]"
          >
            <div>
              <div class="add-user-message-title">
                Begäran att lägga till användare
              </div>
              <div class="add-user-message-subtitle">
                Skickad av {{ message.creator.name }}
              </div>
              <div class="add-user-message-date">
                {{ formatDate(message.createdAt) }}
              </div>
            </div>
            <div class="message-overview-actions">
              <font-awesome-icon
                icon="times"
                size="lg"
                class="clickable"
                @click="onRemoveMessage(message)"
              ></font-awesome-icon>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="showMessageDetails"
        :class="[
          'message-content',
          { 'message-content-divider': showMessagesOverview },
        ]"
      >
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import MessageService from "../../services/messages.service.js";
import AddUserModal from "../modals/add-user-modal.vue";
import moment from "moment";

export default {
  components: {
    AddUserModal,
  },
  data: function () {
    return {
      messages: [],
      fetchMessagesError: false,
    };
  },
  computed: {
    noMessages: function () {
      return this.messages.length == 0;
    },
    addUserMessages: function () {
      return this.messages.filter((x) => x.code === "user:add");
    },
    isMediumPlusView: function () {
      return this.$store.getters["isMediumPlusView"];
    },
    routerParamId: function () {
      return this.$route.params.id;
    },
    showMessageDetails: function () {
      return (
        this.isMediumPlusView || (!this.isMediumPlusView && this.routerParamId)
      );
    },
    showMessagesOverview: function () {
      return (
        this.isMediumPlusView || (!this.isMediumPlusView && !this.routerParamId)
      );
    },
  },
  methods: {
    onRemoveMessage: function (message) {
      MessageService.delete(message.id)
        .then(() => {
          if (this.routerParamId == message.id) {
            this.$router.replace({ path: "/admin/messages" });
          }
          this.getAdminMessages();
        })
        .catch((e) => {
          console.log(e);
          this.$bus.dispatch("showToastFailure", {
            title: "Kunde inte ta bort meddelandet",
            message:
              "Försöket att ta bort meddelandet misslyckades. Kolla loggarna för mer information",
          });
        });
    },
    isSelectedMessage: function (message) {
      return this.$route.params.id == message.id;
    },
    onSelectMessage: function (message) {
      if (this.isSelectedMessage(message)) {
        return;
      }
      this.$router.push({ path: `/admin/messages/${message.id}` });
    },
    formatDate: function (isoDate) {
      return moment(isoDate).format("YYYY-MM-DD");
    },
    getAdminMessages: function () {
      MessageService.getAdminMessages()
        .then((messages) => {
          this.messages = messages ? messages : [];
        })
        .catch((e) => {
          console.log(e);
          this.fetchMessagesError = true;
        });
    },
  },
  mounted() {
    this.$bus.on("markRead", (data) => {
      if (data.messageId) {
        this.messages.forEach((m) => {
          if (m.id === data.messageId) {
            Object.assign(m, { isRead: true });
          }
        });
      }
    });
    this.getAdminMessages();
  },
  beforeDestroy() {
    this.$bus.remove("markRead");
  },
};
</script>

<style scoped>
.message-overview {
  cursor: pointer;
}

.add-user-message-title {
  font-weight: bold;
}

.add-user-message-date,
.add-user-message-subtitle {
  font-size: 14px;
}

.message-inbox {
  width: 100%;
  display: flex;
}

.message-content {
  width: 100%;

  padding-left: 15px;
  padding-right: 15px;
}

.message-content-divider {
  margin-left: 15px;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}

.selected-message {
  background-color: rgba(52, 58, 64, 0.2);
  cursor: default;
}

.message-list {
  min-width: 250px;
}

.message-entry {
  display: flex;
  justify-content: space-between;
}
</style>