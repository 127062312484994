import RecipeService from '../../services/recipes.service.js'
import UserService from '../../services/user.service.js'

const initialState = () => {
  return {
    recipes: [],
    selectedRecipe: {},
    metadata: {}
  }
}

export const recipes = {
  namespaced: true,
  state: initialState(),
  actions: {
    setInitState({ commit }) {
      commit("setInitState", initialState())
    },
    create(_, args) {
      return RecipeService.createRecipe(args.recipe.recipeBankID,
        {
          name: args.recipe.name,
          source: args.recipe.source,
          notes: args.recipe.notes,
          tags: args.recipe.tags,
          status: args.recipe.status,
          rating: args.recipe.rating
        }).then(
          recipe => {
            return Promise.resolve(recipe)
          },
          error => Promise.reject(error)
        )
    },
    addRecipeImage() {
      return Promise.resolve(true)
    },
    search({ commit }, args) {
      return RecipeService.search(args.searchFilter)
        .then(
          result => {
            commit('setMetaData', result.metadata)
            commit('setRecipes', result.recipes)
            return Promise.resolve(result)
          },
          error => Promise.reject(error)
        )
    },
    selectRecipe({ commit }, args) {
      return RecipeService.get(args.id)
        .then(
          recipe => {
            commit('setSelectedRecipe', recipe)
            var getUser = UserService.get(recipe.createdBy)
            return Promise.all([
              Promise.resolve(recipe),
              getUser
            ])
          },
          error => Promise.reject(error)
        ).then(
          results => {
            var recipe = results[0]
            var user = results[1]

            recipe = { ...recipe, userName: user.name }
            commit('setSelectedRecipe', recipe)
            return Promise.resolve(recipe)
          },
          error => Promise.reject(error)
        );
    },
    remove({ commit, state }, args) {
      return RecipeService.delete(args.recipe)
        .then(() => {
          if (args.recipe.id === state.selectRecipe?.id) {
            commit('setSelectedRecipe', {})
          }
          return Promise.resolve(true)
        })
    }
  },
  mutations: {
    setInitState(state, initialState) {
      Object.assign(state, initialState)
    },
    setRecipes(state, recipes) {
      state.recipes = recipes
    },
    addRecipes(state, recipes) {
      state.recipes.push(...recipes)
    },
    setMetaData(state, metadata) {
      state.metadata = metadata
    },
    setSelectedRecipe(state, recipe) {
      state.selectedRecipe = recipe
    }
  }
};