import MenuService from '../../services/menu.service.js';

const initialState = () => {
  return {
    menu: {}
  }
}

export const menu = {
  namespaced: true,
  state: initialState(),
  getters: {

  },
  actions: {
    setInitState({ commit }) {
      commit("setInitState", initialState())
    },
    fetchMenu({ commit }, args) {
      return MenuService.getMenuForRecipeBank(args.recipeBankID)
        .then(
          menu => {
            commit('setMenu', menu)
            return Promise.resolve(menu)
          },
          error => {
            return Promise.reject(error);
          }
        )
    },
    create(_, args) {
      return MenuService.createMenu(args.recipeBankID).then(
        menu => Promise.resolve(menu),
        error => Promise.reject(error)
      )
    },
    addRecipe({ commit, state }, args) {
      return MenuService.addRecipe(state.menu.id, args.recipe.id)
        .then(
          () => {
            commit('addRecipe', args.recipe)
            return Promise.resolve(args.recipe)
          },
          error => Promise.reject(error)
        )
    },
    removeRecipe({ commit, state }, args) {
      return MenuService.removeRecipe(state.menu.id, args.recipe.id)
        .then(
          () => {
            commit('removeRecipe', args.recipe)
            return Promise.resolve()
          },
          error => Promise.reject(error)
        )
    }
  },
  mutations: {
    setInitState(state, initialState) {
      Object.assign(state, initialState)
    },
    setMenu(state, menu) {
      state.menu = menu
    },
    addRecipe(state, recipe) {
      state.menu.entries.push(recipe)
    },
    removeRecipe(state, recipe) {
      var index = state.menu.entries.findIndex(e => e.id === recipe.id)
      if (index >= 0) {
        state.menu.entries.splice(index, 1)
      }
    }
  }
};