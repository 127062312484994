<template>
  <b-modal
    :id="modalId"
    title="Importera recept"
    :visible="show"
    no-close-on-backdrop
    @hide="onClose"
    @ok="onSubmit"
    :cancel-title="$t('common.cancel')"
    :ok-title="$t('common.save')"
    :ok-disabled="!recipeBank.id"
  >
    <p>
      {{ informationalText }}
    </p>
    <form>
      <b-form-select v-model="recipeBank" :options="selectOpts"></b-form-select>
    </form>

    <p class="mt-5">{{ recipesText }}</p>
    <div v-for="(recipe, index) in recipes" :key="index">{{ recipe.name }}</div>
  </b-modal>
</template>

<script>
import RecipeBankService from "../../services/recipebank.service.js";
import RecipesService from "../../services/recipes.service.js";

export default {
  props: {
    modalId: {
      type: String,
      required: true,
    },
  },
  data: function () {
    return {
      recipeShare: {},
      recipeBank: {},
      show: false,
    };
  },
  computed: {
    userId: function () {
      return this.$store.getters["auth/currentUser"]?.id ?? 0;
    },
    isSelf: function () {
      return this.recipeShare.senderId === this.userId;
    },
    recipes: function () {
      return this.recipeShare?.recipes ?? [];
    },
    isMultiRecipe: function () {
      return this.recipeShare?.recipes?.length > 1;
    },
    informationalText: function () {
      var sender = this.isSelf ? "du själv" : this.recipeShare?.sender?.name;
      if (this.isMultiRecipe) {
        return `Här är recepten som ${sender} vill dela med dig. För att importera måste du först välja receptbank att importera recepten till`;
      }
      return `Här är receptet som ${sender} vill dela med dig. För att importera måste du först välja receptbank att importera receptet till`;
    },
    recipesText: function () {
      if (this.isMultiRecipe) {
        return `De recept som delats med dig är:`;
      }
      return `Det recept som delats med dig är:`;
    },
    recipeBanks: function () {
      return this.$store.state.recipebanks.recipeBankSummary;
    },
    selectOpts: function () {
      var opts = [
        {
          value: {},
          text: "Välj receptbank",
          disabled: true,
        },
      ];
      this.recipeBanks.forEach((rb) => {
        opts.push({ value: { ...rb }, text: rb.name });
      });
      return opts;
    },
  },
  methods: {
    onClose: function () {
      this.recipeBank = {};
      this.show = false;
    },
    onSubmit: function () {
      var data = { recipes: [...this.recipeShare.recipes] };
      var file = new File([JSON.stringify(data)], "recipes.json", {
        type: "application/json",
      });

      RecipeBankService.importRecipes(this.recipeBank.id, file)
        .then(() => {
          return this.$bus.dispatch("showToastSuccess", {
            title: "Import av recept sänd",
            message: `Fort var det gjort! Importen är klar.`,
          });
        })
        .then(() => {
          return RecipesService.deleteRecipeShare(this.recipeShare.id)
            .then(() => this.$emit("recipeShareHandled"))
            .catch((e) => {
              console.log(e);
              return this.$bus.dispatch("showToastFailure", {
                title: "Kunde inte ta bort receptdelning",
                message: `Ojdå! Något gick fel när receptdelningen skulle tas bort`,
              });
            });
        })
        .catch((e) => {
          console.log(e);
          return this.$bus.dispatch("showToastFailure", {
            title: "Kunde inte importera recept",
            message: `Ojdå! Något gick fel när recepten skulle importeras. Uppdatera sidan och försök igen eller kontakta supporten'`,
          });
        });
    },
  },
  mounted() {
    this.$bus.on(this.modalId, (data) => {
      this.recipeShare = data.recipeShare;
      this.show = true;
    });
  },
  beforeDestroy() {
    this.$bus.remove();
  },
};
</script>

<style>
</style>