import Vue from 'vue';
import Vuex from 'vuex';

import { auth } from './modules/auth.module.js';
import { recipes } from './modules/recipes.module.js';
import { recipebanks } from './modules/recipebanks.module.js'
import { menu } from './modules/menu.module.js'
import { users } from './modules/users.module.js'
import { admin } from './modules/admin.module.js'

Vue.use(Vuex);

const initialState = () => {
  return { viewPort: {} }
}

const store = new Vuex.Store({
  modules: {
    auth,
    recipebanks,
    recipes,
    menu,
    users,
    admin
  },
  state: initialState(),
  getters: {
    isWideView: state => state.viewPort.width > 575,
    isMediumView: state => state.viewPort.width < 768,
    isMediumPlusView: state => state.viewPort.width >= 768,
    isCollapsedView: state => state.viewPort.width < 992
  },
  actions: {
    resetState({ commit, dispatch }) {
      dispatch("auth/setInitState")
      dispatch("recipebanks/setInitState")
      dispatch("recipes/setInitState")
      dispatch("menu/setInitState")
      dispatch("users/setInitState")
      dispatch("admin/setInitState")
      commit("setInitState", initialState())
    }
  },
  mutations: {
    setInitState(state, initialState) {
      Object.assign(state, initialState)
    },
    setViewPort(state, viewPort) {
      state.viewPort = { ...viewPort }
    },
  },
  strict: process.env.NODE_ENV !== 'production'
})

export default store;