import Vue from 'vue';
import VueRouter from 'vue-router'

import {
  Login,
  Menu,
  NotFound,
  Home,
  Recipes,
  Admin,
  RecipeDetails,
  Profile,
  AdminUsers,
  AdminRecipeBanks,
  AdminMessages,
  Activation,
  PasswordReset,
  RequestPasswordReset,
  RecipeBankDetails,
  Message
} from './components/pages/pages.barrel.js'
import TokenService from './services/token.service.js';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      redirect: '/menu',
      meta: { requiresAuth: true },
      children: [
        {
          name: 'menu',
          path: '/menu',
          component: Menu,
          meta: { requiresAuth: true, requireBank: true }
        },
        {
          name: 'recipes',
          path: '/recipes',
          component: Recipes,
          meta: { requiresAuth: true, requireBank: true },
        },
        {
          name: "recipe-details",
          path: "/recipes/:id",
          component: RecipeDetails,
          meta: { requiresAuth: true, requireBank: true },
        },
        {
          name: "recipebank-details",
          path: "/recipebanks/:id",
          component: RecipeBankDetails,
          meta: { requiresAuth: true, requireBank: true },
        },
        {
          name: 'admin',
          path: '/admin',
          component: Admin,
          redirect: "/admin/users",
          meta: { requiresAuth: true, requiredRole: 'admin' },
          children: [
            {
              name: "admin-users",
              path: "/admin/users",
              component: AdminUsers,
              meta: { requiresAuth: true, requiredRole: 'admin' },
            },
            {
              name: "admin-recipebanks",
              path: "/admin/recipebanks",
              component: AdminRecipeBanks,
              meta: { requiresAuth: true, requiredRole: 'admin' },
            },
            {
              name: "admin-messages",
              path: "/admin/messages",
              component: AdminMessages,
              meta: { requiresAuth: true, requiredRole: 'admin' },
              children: [
                {
                  name: 'admin-message',
                  path: '/admin/messages/:id',
                  component: Message,
                  meta: { requiresAuth: true, requiredRole: 'admin' }
                },
              ]
            }
          ]
        },
        {
          name: 'profile',
          path: '/profile',
          component: Profile,
          meta: { requiresAuth: true }
        }
      ]
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: { hideNavbar: true }
    },
    {
      path: "/activation",
      name: "activation",
      component: Activation,
      meta: { hideNavbar: true }
    },
    {
      path: "/password-reset",
      name: "password-reset",
      component: PasswordReset,
      meta: { hideNavbar: true }
    },
    {
      path: "/request-password-reset",
      name: "request-password-reset",
      component: RequestPasswordReset,
      meta: { hideNavbar: true }
    },
    {
      path: '*',
      name: 'not-found',
      component: NotFound,
      meta: { hideNavbar: true }
    }
  ]
});

router.beforeEach((to, from, next) => {
  var authToken = TokenService.getLocalAccessToken()
  if (to.meta?.requiresAuth && !authToken) {
    next({ path: '/login', query: { 'redirect': to.fullPath } });
  } else {
    next();
  }
});

export {
  router
}