<template>
  <div class="container app-page-card">
    <edit-recipe-bank-modal
      modalId="editRecipeBank"
      @recipeBankUpdated="onRecipeBankUpdated"
    ></edit-recipe-bank-modal>
    <div v-if="loading" class="login-loading-hud p-2">
      <div class="loader awesome-spin"></div>
    </div>
    <div v-else-if="error" class="text-center">
      <font-awesome-icon icon="bug" size="5x"></font-awesome-icon>
      <div class="mt-5 default-text">
        <span
          >Ajdå! Det ser ut som att något gick fel här.
          <span class="font-italic clickable navigation" @click="reloadPage"
            >Prova igen</span
          >
          eller
          <span class="font-italic clickable navigation" @click="goBack"
            >gå tillbaka</span
          ></span
        >
      </div>
    </div>
    <div v-else>
      <div class="section-title">{{ recipeBank.name }}</div>
      <div class="row">
        <div class="col-12 col-sm-4 mt-4">
          <div class="profile-data-header">Skapad av</div>
          <div class="mt-2 profile-data">{{ creator }}</div>
        </div>
        <div class="col-12 col-sm-4 mt-4">
          <div class="profile-data-header">Skapad</div>
          <div class="mt-2 profile-data">
            {{ toDate(recipeBank.createdAt) }}
          </div>
        </div>
        <div class="col-12 col-sm-4 mt-4">
          <div class="profile-data-header">Antal recept</div>
          <div
            :class="['mt-2', 'profile-data', { 'centered-number': isWideView }]"
          >
            {{ recipeBank.recipeCount }}
          </div>
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col-12 col-sm-4">
          <button
            class="btn btn-sm btn-primary w-100"
            @click="onEditRecipeBank"
          >
            Byt namn
          </button>
        </div>
        <div :class="['col-12', 'col-sm-4', { 'mt-2': !isWideView }]">
          <button
            class="btn btn-sm btn-secondary w-100"
            @click="onExportRecipeBank"
          >
            Exportera
          </button>
        </div>
        <div :class="['col-12', 'col-sm-4', { 'mt-2': !isWideView }]">
          <button
            v-if="isOwner"
            class="btn btn-sm btn-danger w-100"
            @click="onDelete"
          >
            Ta bort
          </button>
          <button v-else class="btn btn-sm btn-danger w-100" @click="onLeave">
            Lämna
          </button>
        </div>
      </div>
      <hr />
      <div class="mt-5">
        <div class="section-header pb-2">Medlemmar</div>
        <div class="pt-3 pb-4">
          <div>
            <form class="w-100">
              <div class="row">
                <div class="col-12 col-sm-10 my-1">
                  <auto-complete-input
                    :placeholder="'Fyll i email...'"
                    :suggestions="members"
                    v-model="member"
                    :matcher="
                      (entry, filter) => {
                        lowerCaseFilter = filter.toLowerCase();
                        return (
                          entry.name.toLowerCase().includes(lowerCaseFilter) ||
                          entry.email.toLowerCase().includes(lowerCaseFilter)
                        );
                      }
                    "
                  ></auto-complete-input>
                </div>
                <div class="col-12 col-sm-2">
                  <button
                    type="submit"
                    class="btn btn-secondary btn-sm w-100 my-1"
                    :disabled="!member.value"
                    @click.prevent="onAddMember"
                  >
                    Lägg till
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div class="container">
          <div
            v-if="isWideView"
            :class="[
              isWideView ? 'text-center' : 'text-left',
              'row',
              'table-row',
              'list-data-header',
            ]"
          >
            <div :class="['col-4', { 'text-left': isWideView }]">Namn</div>
            <div :class="['col-4', { 'text-left': isWideView }]">Email</div>
            <div class="col-12 col-sm-4"></div>
            <div class="col-12 list-header-divider"></div>
          </div>
          <div
            v-for="(member, index) in recipeBank.users"
            :key="member.id"
            class="row table-row"
          >
            <div v-if="index > 0" class="col-12 p-0"><hr /></div>
            <div class="col-12 col-sm-4">{{ member.name }}</div>
            <div class="col-12 col-sm-4">{{ member.email }}</div>
            <div class="col-12 col-sm-2">
              <b-badge v-if="isOwner" variant="success">Ägare</b-badge>
              <b-badge v-else variant="primary">Medlem</b-badge>
            </div>
            <div class="col-12 col-sm-2">
              <div v-if="isOwner" :class="{ 'text-right': isWideView }"></div>
              <div
                v-else-if="isWideView"
                :class="['w-100', 'pr-4', 'text-right']"
              >
                <font-awesome-icon
                  icon="times"
                  size="lg"
                  class="clickable"
                ></font-awesome-icon>
              </div>
              <div v-else>
                <button class="btn btn-sm btn-danger">Ta bort</button>
              </div>
            </div>
          </div>
          <div class="row table-row">
            <div v-if="isWideView" class="col-12 p-3"></div>
            <hr v-else />
          </div>
          <div
            v-for="(member, index) in recipeBank.invitedUsers"
            :key="member.id"
            class="row table-row"
          >
            <div v-if="index > 0" class="col-12 p-0"><hr /></div>
            <div class="col-12 col-sm-4">{{ member.name }}</div>
            <div class="col-12 col-sm-4">{{ member.email }}</div>
            <div class="col-12 col-sm-2">
              <b-badge v-if="isOwner" variant="info">Inbjuden</b-badge>
            </div>
            <div class="col-12 col-sm-2">
              <!-- TODO: ability to remove invitation -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserService from "../../services/user.service.js";
import RecipeBankService from "../../services/recipebank.service.js";
import EditRecipeBankModal from "../modals/edit-recipe-bank-modal.vue";
import AutoCompleteInput from "../controls/autocomple-input.vue";
import moment from "moment";

export default {
  components: {
    EditRecipeBankModal,
    AutoCompleteInput,
  },
  data: function () {
    return {
      recipeBank: {},
      loading: false,
      error: false,
      member: { value: "" },
      users: [],
    };
  },
  computed: {
    members: function () {
      var members = [];
      this.users
        .filter((u) => u.id !== this.user.id)
        .forEach((u) => {
          members.push({
            value: u.email,
            display: `${u.name} (${u.email})`,
            name: u.name,
            email: u.email,
          });
        });
      return members;
    },
    recipeBankId: function () {
      return this.$route.params.id;
    },
    creator: function () {
      return this.recipeBank.creator?.name ?? "";
    },
    isWideView: function () {
      return this.$store.getters["isWideView"];
    },
    user: function () {
      return this.$store.getters["auth/currentUser"];
    },
    isOwner: function () {
      return this.recipeBank.createdBy == this.user.id;
    },
  },
  methods: {
    onAddMember: function () {
      RecipeBankService.inviteUser(this.recipeBank.id, this.member.value)
        .then(() => {
          this.member = { value: "" };
          this.fetchRecipeBank();
        })
        .then(() => {
          return this.$bus.dispatch("showToastSuccess", {
            title: "Kunde inte skicka medlemsinbjudan",
            message: `Det går bra nu! Inbjudan till '${this.recipeBank.name}' har skickats till '${this.member.value}'.`,
          });
        })
        .catch((e) => {
          console.log(e);
          this.$bus.dispatch("showToastFailure", {
            title: "Kunde inte skicka medlemsinbjudan",
            message: `Aj då! Inbjudan till '${this.recipeBank.name}' kunde inte skickas till '${this.member.value}'. Kontrollera e-postadressen eller kontakta supporten för mer hjälp.`,
          });
        });
    },
    reloadPage: function () {
      window.location.reload();
    },
    goBack: function () {
      this.$router.go(-1);
    },
    toDate: function (isoDate) {
      return moment(isoDate).format("YYYY-MM-DD");
    },
    onEditRecipeBank: function () {
      this.$bus.dispatch("editRecipeBank", { recipeBank: this.recipeBank });
    },
    onRecipeBankUpdated: function () {
      this.fetchRecipeBank();
    },
    onExportRecipeBank: function () {
      RecipeBankService.exportRecipesFromBank(this.recipeBank.id).then(
        (recipes) => {
          var dataStr =
            "data:text/json;charset=utf-8," + encodeURIComponent(recipes);

          var download = document.createElement("a");
          download.setAttribute("href", dataStr);
          download.setAttribute(
            "download",
            `${this.recipeBank.name}_recipes.json`
          );
          download.click();
          download.remove();
        }
      );
    },
    onDelete: function () {
      this.$bvModal
        .msgBoxConfirm(
          `Är du säkert på att du vill ta bort receptbanken '${this.recipeBank.name}'?`,
          {
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "Ta bort",
            cancelTitle: "Avbryt",
          }
        )
        .then((result) => {
          if (result) {
            return this.$store
              .dispatch("recipebanks/removeRecipeBank", {
                recipeBankId: this.recipeBank.id,
              })
              .then(() => this.$router.go(-1))
              .then(() => this.$store.dispatch("recipebanks/fetchRecipeBank"));
          }
          return Promise.resolve();
        })
        .catch((e) => {
          console.log(e);
          this.$bus.dispatch("showToastFailure", {
            title: "Något gick fel!",
            message: `Ojdå! Vi kunde inte ta bort receptbanken '${this.recipeBank.name}'. Uppdatera sidan och försök igen eller kontakta supporten`,
          });
        });
    },
    onLeave: function () {
      this.$bvModal
        .msgBoxConfirm(
          `Är du säkert på att du vill ta bort dig själv som medlem i receptbanken '${this.recipeBank.name}'?`,
          {
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "Ta bort",
            cancelTitle: "Avbryt",
          }
        )
        .then((result) => {
          if (result) {
            return RecipeBankService.removeMember(
              this.recipeBank.id,
              this.$store.state.auth.currentUser.id
            ).then(() => this.$router.go(-1));
          }
          return Promise.resolve();
        })
        .catch((e) => {
          console.log(e);
          this.$bus.dispatch("showToastFailure", {
            title: "Något gick fel!",
            message: `Ojdå! Vi kunde inte ta bort dig från receptbanken '${this.recipeBank.name}'. Uppdatera sidan och försök igen eller kontakta supporten`,
          });
        });
    },
    fetchRecipeBank: function () {
      this.loading = true;
      RecipeBankService.get(this.recipeBankId)
        .then((recipeBank) => {
          this.recipeBank = recipeBank;
        })
        .catch((e) => {
          console.log(e);
          this.error = true;
        })
        .finally(() => (this.loading = false));
    },
    fetchUsers: function () {
      UserService.searchUsers({
        text: "",
        page: 1,
        pageSize: 10000,
        sort: "name",
      })
        .then((result) => {
          this.users = result.users ?? [];
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  mounted() {
    this.fetchRecipeBank();
    this.fetchUsers();
  },
};
</script>

<style scoped>
.navigation:hover {
  text-decoration: underline;
}

.section-title {
  font-size: 1.75rem;
  font-weight: bold;
}

.centered-number {
  margin-left: 30px;
}
</style>