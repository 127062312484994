<template>
  <div class="recipe-card" @click="onRecipeClicked">
    <div class="recipe-card-board">
      <div clasS="card-row">
        <div class="recipe-header">{{ recipe.name }}</div>
        <b-dropdown
          size="lg"
          variant="link"
          toggle-class="text-decoration-none"
          dropleft
          no-caret
          class="recipe-actions text-right"
        >
          <template #button-content>
            <font-awesome-icon icon="ellipsis-v"></font-awesome-icon>
          </template>
          <b-dropdown-item @click.stop="onAddToMenu"
            >Lägg till i menyn</b-dropdown-item
          >
          <b-dropdown-item @click.stop="onShareRecipe">Dela</b-dropdown-item>
          <b-dropdown-item class="text-danger" @click.stop="onRemoveRecipe"
            >Ta bort</b-dropdown-item
          >
        </b-dropdown>
      </div>
      <div v-if="isUrlSource(recipe.source)">
        <a @click.stop="onSourceClicked" class="source-link">
          <span class="recipe-source">
            <font-awesome-icon
              icon="external-link-alt"
              size="xl"
            ></font-awesome-icon>
            {{ getDomainName(recipe.source) }}
          </span>
        </a>
      </div>
      <div v-else>
        <div class="recipe-source">{{ recipe.source }}</div>
      </div>
      <div class="w-100 recipe-tags mb-2">
        <b-badge
          v-for="tag in recipe.tags"
          :key="tag"
          variant="dark"
          class="mr-1 mt-1"
          >{{ tag }}</b-badge
        >
      </div>
      <div class="card-row card-row-last align-items-end">
        <div class="recipe-created">Tillagt {{ toDate(recipe.created) }}</div>
        <div class="recipe-notifiers">
          <font-awesome-icon
            v-if="recipe.notes"
            :icon="['far', 'comment-dots']"
          ></font-awesome-icon>
          <font-awesome-icon
            v-if="recipe.images && recipe.images.length > 0"
            :icon="['far', 'image']"
            class="ml-2"
          ></font-awesome-icon>
          <div v-if="recipe.rating" class="rating-short">
            <span
              ><font-awesome-icon
                :icon="['fas', 'star']"
                class="ml-2"
              ></font-awesome-icon>
              <span class="rating-short-text">{{ recipe.rating }}</span></span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SourceParserMixin from "../mixins/source-parser-mixin.js";
import moment from "moment";

export default {
  props: {
    recipe: {
      type: Object,
      required: true,
    },
  },
  mixins: [SourceParserMixin],
  methods: {
    onShareRecipe: function () {
      this.$emit(`shareRecipe`, { recipe: { ...this.recipe } });
    },
    toDate: function (isoDate) {
      return moment(isoDate).format("YYYY-MM-DD");
    },
    onAddToMenu: function () {
      this.$emit("addToMenu", { recipe: this.recipe });
    },
    onRemoveRecipe: function () {
      this.$emit("removeRecipe", { recipe: this.recipe });
    },
    onRecipeClicked: function () {
      this.$router.push({ path: `/recipes/${this.recipe.id}` });
    },
    onSourceClicked: function () {
      window.location.href = this.recipe.source;
    },
  },
};
</script>

<style scoped>
.recipe-card {
  width: 100%;
  height: 100%;
  background-image: url("../../assets/wooden-background-small.jpg");
  background-color: 0.75rem;
  border-radius: 0.25rem;
  padding: 0.35rem;
  box-shadow: 3px 3px 10px 2px grey;
}

.recipe-card-board {
  display: flex;
  flex-direction: column;
  background-image: url("../../assets/white-paper-small.jpg");
  border-radius: 0.15rem;
  width: 100%;
  height: 100%;
  text-align: left;
  padding: 1.5rem;
  box-shadow: 0.5px 0.5px 4px 0px grey;
}

.recipe-header {
  font-size: 1.25rem;
  font-weight: bold;
}

.recipe-source {
  min-height: 1.5rem;
  font-size: 1rem;
  font-style: italic;
}

.recipe-created {
  font-size: 0.75rem;
}

.card-row {
  display: flex;
  justify-content: space-between;
  align-items: top;
  font-size: 1.25rem;
}

.card-row-last {
  margin-top: auto;
}

.recipe-notifiers {
  display: flex;
  align-items: center;
}

.card-row >>> .btn-lg {
  padding: 0 !important;
}

.card-row >>> .btn:focus {
  box-shadow: none !important;
  outline: none !important;
}

.card-row >>> .btn.focus {
  box-shadow: none !important;
  outline: none !important;
}

.card-row >>> .btn:focus-visible {
  box-shadow: none !important;
  outline: none !important;
}

.recipe-actions {
  min-width: 10%;
}

.recipe-actions >>> button {
  display: flex;
  justify-content: flex-end;
  margin-top: 3px;
}
</style>