export function isLocalStorageAvailable() {
  var test = 'test';
  try {
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
}

export function store(key, item) {
  if (isLocalStorageAvailable()) {
    localStorage.setItem(key, item.toString())
  }
}

export function load(key) {
  if (isLocalStorageAvailable()) {
    return localStorage.getItem(key)
  }
  return null
}

export function remove(key) {
  if (isLocalStorageAvailable()) {
    localStorage.removeItem(key)
  }
}

export const currentRecipeBankIdKey = "selectedRecipeBankId"