<template>
  <div>
    <send-support-message-modal
      :modalId="'sendSupportMessage'"
    ></send-support-message-modal>
    <div class="w-100 centered">
      <div v-if="promptCreateRecipeBank" class="recipebank-prompt">
        <create-recipe-bank></create-recipe-bank>
      </div>
      <router-view v-else-if="initialized"></router-view>
    </div>
  </div>
</template>

<script>
import CreateRecipeBank from "../widgets/create-recipe-bank.vue";
import SendSupportMessageModal from "../modals/send-support-message-modal.vue";

export default {
  components: {
    CreateRecipeBank,
    SendSupportMessageModal,
  },
  data: function () {
    return {
      loading: false,
      error: "",
      initialized: false,
    };
  },
  computed: {
    promptCreateRecipeBank: function () {
      return (
        this.initialized &&
        this.$store.getters["recipebanks/recipebanksCount"] == 0 &&
        this.$route.meta.requireBank
      );
    },
  },
  mounted() {
    this.loading = true;
    this.$store
      .dispatch("recipebanks/fetchRecipeBank")
      .catch((e) => {
        console.log(e);
        this.error = "Fel vid hämtning";
      })
      .finally(() => {
        this.loading = false;
        this.initialized = true;
      });
  },
};
</script>

<style scoped>
.recipebank-prompt {
  padding-left: 15px;
  padding-right: 15px;
}
</style>