import api from './api.js'

class UserService {
  get(id) {
    return api.get(`/users/${id}`)
      .then(response => response.data?.user)
  }
  getSessionUser() {
    return api.get(`/session/user`).then(
      response => response.data?.user
    )
  }
  deleteUser() {
    return api.delete(`/users`)
      .then(response => response.data)
  }
  deleteUserById(userId) {
    return api.delete(`/users/${userId}`)
      .then(response => response.data)
  }
  searchUsers(filters) {
    return api.get(`/users?text=${filters.text}&page=${filters.page}&page_size=${filters.pageSize}&sort=${filters.sort}`)
      .then(response => response.data)
  }
  sendActivationLink(userEmail) {
    return api.post(`/tokens/activation`, { email: userEmail })
  }
  sendPasswordResetLink(userEmail) {
    return api.post(`/tokens/password-reset`, { email: userEmail })
  }
  updateUser(user) {
    return api.patch(`/users/${user.id}`, { name: user.name, password: user.password })
  }
  updateOwnUser(user) {
    return api.patch(`/users`, { name: user.name, password: user.password })
  }
  createUser(user) {
    return api.post(`/users`, user)
  }
  updatePassword(token, password) {
    return api.put(`/users/password`, { password, token })
  }
  activateUser(token, password) {
    return api.put(`/users/activate`, { token, password })
  }
  rejectActivation(token) {
    return api.post(`/users/activation/reject`, { token })
  }
  getRecipeBankInvitations(userID) {
    return api.get(`/users/${userID}/recipebanks/invitations`)
      .then(response => response.data?.invitations)
  }
  answerRecipeBankInvitation(recipeBankID, join) {
    return api.post(`/users/recipebanks/${recipeBankID}/invitations`, { join })
  }
}

export default new UserService();