<template>
  <div style="position: relative" v-bind:class="{ open: openSuggestion }">
    <input
      class="form-control form-control-sm"
      type="text"
      :value="value.value"
      @input="change"
      @keydown.enter="enter"
      @keydown.down="down"
      @keydown.up="up"
      @keydown.esc="escape"
      @blur="onBlur"
      :placeholder="placeholder"
    />
    <ul class="dropdown-menu" style="width: 100%">
      <li
        v-for="(suggestion, index) in matches"
        :key="index"
        :class="['suggestion', { selected: isActive(index) }]"
        @click="suggestionClick(suggestion)"
        @mouseover="onMouseOver(index)"
      >
        {{ suggestion.display }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      open: false,
      current: 0,
      text: "",
    };
  },
  props: {
    suggestions: {
      type: Array,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
    matcher: {
      type: Function,
      default(x, filter) {
        return x.String().indexOf(filter) >= 0;
      },
    },
    placeholder: {
      type: String,
      default: "",
    },
  },
  computed: {
    matches() {
      return this.suggestions.filter((x) => {
        return this.matcher(x, this.value.value);
      });
    },
    openSuggestion() {
      return (
        this.value.value !== "" &&
        this.matches.length != 0 &&
        this.open === true
      );
    },
  },
  methods: {
    onBlur: function () {
      setTimeout(() => (this.open = false), 250);
    },
    onMouseOver: function (index) {
      this.current = index;
    },
    enter(e) {
      e.preventDefault();
      e.stopPropagation();
      if (this.open) {
        var val = this.matches[this.current];
        this.$emit("input", val);
        this.open = false;
      }
    },
    up(e) {
      e.preventDefault();
      if (this.current > 0) this.current--;
    },
    down(e) {
      e.preventDefault();
      if (this.current < this.matches.length - 1) this.current++;
    },
    escape(e) {
      e.preventDefault();
      this.open = false;
    },
    isActive(index) {
      return index === this.current;
    },
    change(e) {
      this.$emit("input", { value: e.target.value });
      if (this.open == false) {
        this.open = true;
        this.current = 0;
      }
    },
    suggestionClick(val) {
      console.log(val);
      this.$emit("input", val);
      this.open = false;
    },
  },
};
</script>

<style scoped>
.open > .dropdown-menu {
  display: block !important;
}

.suggestion {
  padding: 6px 12px;
  cursor: pointer;
}

.selected {
  color: #fff;
  background-color: #343a40;
}
</style>