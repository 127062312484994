<template>
  <div class="app-page login-page">
    <div class="login-container activation-container app-card">
      <div :class="['login-card', { blurred: loading }]">
        <img src="../../assets/Logo-primary.svg" />

        <div class="mt-4">
          Tjoho! Nu kan du alldeles strax komma igång. Ange bara ett lösenord så
          är saken biff.
        </div>
        <form>
          <div class="input-group mt-3">
            <input
              :type="passwordInputType"
              v-model="password"
              class="form-control"
              placeholder="Lösenord"
              aria-describedby="user-input1"
              autocomplete="new-password"
            />
            <div class="input-group-append">
              <span
                class="input-group-text"
                id="user-input1"
                style="min-width: 3rem"
                ><font-awesome-icon
                  :icon="passwordInputIcon"
                  class="clickable"
                  @click="showPassword = !showPassword"
                ></font-awesome-icon
              ></span>
            </div>
          </div>
          <div class="font-italic text-left cookie-note mt-3">
            Den här webbplatsen använder
            <a
              href="https://www.pts.se/cookies"
              class="cookie-link"
              target="_blank"
              >kakor (cookies)</a
            >
            för att fungera. Om du väljer att aktivera ditt konto och använda
            tjänsten godkänner du detta.
          </div>
          <div id="activateUserButton" class="w-100 mt-4">
            <button
              type="submit"
              class="btn btn-primary w-100"
              :disabled="!validPassword"
              @click.prevent="onActivate"
            >
              Aktivera
            </button>
            <b-tooltip
              v-if="!validPassword"
              target="activateUserButton"
              triggers="hover"
            >
              Ange ett lösenord med minst 8 tecken
            </b-tooltip>
          </div>

          <div class="w-100 mt-4">
            <button class="btn btn-outline-danger w-100" @click="onRemoveMe">
              Nej tack, ta bort mina uppgifter
            </button>
          </div>
        </form>
      </div>
      <div v-if="loading" class="login-loading-hud app-card">
        <div class="loader awesome-spin"></div>
      </div>
    </div>
  </div>
</template>

<script>
import UserService from "../../services/user.service.js";
export default {
  data: function () {
    return {
      activationOk: false,
      loading: false,
      password: "",
      showPassword: false,
    };
  },
  computed: {
    validPassword: function () {
      return this.password.length >= 8;
    },
    token: function () {
      return this.$route.query.token;
    },
    passwordInputIcon: function () {
      return this.showPassword ? "eye-slash" : "eye";
    },
    passwordInputType: function () {
      return this.showPassword ? "text" : "password";
    },
  },
  methods: {
    onRemoveMe: function () {
      this.$bvModal
        .msgBoxConfirm(
          `Det här innebär att alla dina uppgifter tas bort och du kommer inte att kunna skapa en användare utan att bli inbjuden på nytt. Vill du fortsätta?`,
          {
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "Ta bort",
            cancelTitle: "Avbryt",
          }
        )
        .then((result) => {
          if (result) {
            this.loading = true;
            return UserService.rejectActivation(this.token)
              .then(() => {
                return this.$bus.dispatch("showToastSuccess", {
                  title: "Dina uppgifter är borttagna",
                  message: `Vad tråkigt att du inte ville använda tjänsten men din önskan är vår lag. Dina uppgifter är borttagna`,
                });
              })
              .then(() => {
                this.router.replace({ name: "login" });
              });
          }
          return Promise.resolve();
        })
        .catch((e) => {
          console.log(e);
          this.$bus.dispatch("showToastFailure", {
            title: "Din begäran kunde inte genomföras",
            message: `Oops! Något gick fel när dina uppgifter skulle raderas. Kontakta oss för att få veta mer.`,
          });
        })
        .finally(() => (this.loading = false));
    },
    onActivate: function () {
      this.loading = true;
      UserService.activateUser(this.token, this.password)
        .then(() => {
          this.$bus.dispatch("showToastSuccess", {
            title: "Din användare är aktiverad",
            message: `Snyggt! Välkommen in i värmen. Du kan nu logga in för att börja samla dina favoritrecept`,
          });
          return this.$router.replace({ name: "login" });
        })
        .catch((e) => {
          console.log(e);
          this.$bus.dispatch("showToastFailure", {
            title: "Kunde inte aktivera användare",
            message: `Ojdå! Din användare kunde inte aktiveras. Kanske har det gått mer än 3 dagar sedan du fick länken?`,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.activation-container {
  max-width: 326px;
}

.cookie-note {
  font-size: 12px;
}

.cookie-link {
  color: rgb(59, 134, 247);
}
</style>