<template>
  <div>
    <b-toast
      id="success-toast"
      solid
      :auto-hide-delay="4000"
      :toaster="toaster"
    >
      <template #toast-title>
        <div class="toast-title">
          <font-awesome-icon
            icon="check"
            class="text-success mr-2"
            size="lg"
          ></font-awesome-icon>
          <strong class="mr-auto">{{ title }}</strong>
        </div>
      </template>
      {{ message }}
    </b-toast>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
  },
  computed: {
    toaster: function () {
      if (this.$store.state.viewPort.width < 576) {
        return "b-toaster-bottom-full";
      }
      return "b-toaster-bottom-right";
    },
  },
};
</script>

<style scoped>
.toast-title {
  display: flex;
  flex-direction: row;
  align-items: center;
}
</style>